.sidebar-main {
    width: 100%;
    max-width: 1280px;
    padding: 0;
    z-index: 1;

    .block.filter {
        margin: 0;

        .filter-content {
            .filter-options {
                display: grid;
                grid-auto-flow: column;
                box-shadow: 0 3px 6px rgba($color-black, .16);
                background-color: $color-white;
                position: relative;

                .filter-options-item {
                    &:hover {
                        cursor: pointer;
                    }

                    .filter-options-title {
                        width: max-content;
                        padding: 20px;
                        margin: 0;
                        text-transform: uppercase;
                        font-size: 0.875rem;

                        &::after {
                            content: "\f105";
                            display: inline-block;
                            font-family: $font-awesome-pro;
                            margin-left: 5px;
                            rotate: z -90deg;
                            transition: .3s;
                        }

                        &[aria-expanded=true]::after {
                            rotate: z 90deg;
                        }
                    }

                    .filter-options-content {
                        display: none;
                        width: 100%;
                        background: $color-white;
                        z-index: -1;
                        left: 0;
                        max-height: 60vh;
                        overflow-y: scroll;

                        .items {
                            display: grid;
                            grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
                            width: 100%;
                            box-shadow: 0 3px 6px rgba($color-black, .16);

                            .item {
                                padding: 10px 20px;

                                &:hover a {
                                    color: $color1;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.column.main {
    width: 100%;
    max-width: 1280px;
    padding: 0;

    .toolbar-products {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin: 30px 0;
        padding: 0;
        gap: 30px;

        &::after,
        &::before {
            content: none;
        }

        .sorter {
            display: flex;
            align-items: center;
            padding: 0;
            position: relative;
            gap: 15px;
            cursor: pointer;

            .sorter-label {
                text-transform: uppercase;
                font-weight: bold;
                color: $color-black;
            }

            #sorter {
                all: unset;
                position: absolute;
                inset: 0;
                margin: 0;
                opacity: 0;
            }

            .sorter-action {
                top: auto;

                &::before {
                    content: "\f105";
                    font-family: $font-awesome-pro;
                    color: $color1;
                    font-size: 16px;
                    line-height: 1;
                    pointer-events: none;
                    cursor: default;
                }

                &[data-value=desc] {
                    rotate: z 90deg;
                }

                &[data-value=asc] {
                    rotate: z -90deg;
                }
            }
        }

        .pages-items {
            display: flex;

            .item {
                display: flex;
                align-items: center;
                >a,
                span {
                    display: grid;
                    place-content: center;
                    aspect-ratio: 1;
                    width: 40px;
                    border-radius: 5px;
                    height: 100%;
                }

                a:visited {
                    color: initial;
                }

                &.current {
                    box-shadow: 0 3px 6px rgba($color-black, .16);
                    border-radius: 5px;
                }

                &:hover> :is(a, span) {
                    background-color: $color1;
                    color: $color-white;
                }

                &:is(.pages-item-previous, .pages-item-next) a {
                    &::before {
                        line-height: 1;
                        color: $color-black;
                        font-size: 2rem;
                    }

                    &:hover::before {
                        color: $color-white;
                    }
                }
            }
        }

        .field.limiter {
            display: flex;
            align-items: center;
            text-transform: uppercase;
            font-weight: bold;
            gap: 5px;

            .limiter-text {
                display: none;
            }

            #limiter {
                background: none;
                border: none;
                padding: 0;
                margin: 0;
                font-weight: bold;
            }
        }
    }

    .products.wrapper~.toolbar {
        .sorter {
            display: none;
        }

        .limiter {
            display: none;
        }
    }

    .products {
        .product-items {
            display: grid;
            // grid-template-columns: repeat(auto-fill, minmax(310px, 1fr));

            @media (width > $bp-1024px) {
                grid-template-columns: repeat(4, 1fr);
            }

            @media(width < $bp-768px) {
                grid-template-columns: repeat(2, 1fr);
            }

            @media(width < $bp-480px) {
                display: block;
            }

            grid-auto-flow: dense;
            gap: 35px 20px;
            width: 100%;

            .product-item {
                margin: 0;
                width: 100%;

                .product-item-info {
                    display: grid;
                    height: 100%;
                    width: 100%;
                    gap: 20px;

                    .info-top {
                        position: relative;
                        background-color: $color-white;
                        box-shadow: 0 3px 6px rgba($color-black, .16);
                        padding: 20px;

                        .product-item-name {
                            font-weight: bold;
                            font-size: 19px;
                            color: $color-black;
                        }

                        .product-item-link {
                            color: $color-black;
                            font-weight: bold;
                        }

                        .action.more {
                            position: absolute;
                            right: 10px;
                            bottom: 10px;
                            line-height: 1;

                            i {
                                font-size: 22px;
                            }
                        }

                        .product-image-container {
                            width: 100% !important;
                        }
                    }

                    .info-bottom {
                        background-color: $color-white;
                        box-shadow: 0 3px 6px rgba($color-black, .16);

                        .actions {
                            margin: 0;

                            .actions-primary {
                                width: 100%;

                                .action.tocart.primary {
                                    width: 100%;
                                    margin: 0;
                                    padding: 15px x0;
                                    text-transform: uppercase;
                                    font-size: 16px;
                                    background: #5AA466;
                                    border-color: #5AA466;

                                    &:hover{
                                        background: white;
                                        color: #5AA466;
                                    }

                                    &::after{
                                        content: "\e4cf";
                                        font: var(--fa-font-solid);
                                        margin-left: 0.3125rem;
                                    }
                                    // &::after {
                                    //     content: "\f07a";
                                    //     font-family: $font-awesome-pro;
                                    //     margin-left: 5px;
                                    // }
                                }
                            }
                        }
                    }
                }
            }

            .content-item {
                display: flex;
                justify-content: center;
                flex-direction: column;
                padding: 50px 40px;
                margin: 30px 0;
                grid-column: span 2;

                &.content-item-5 {
                    display: none;
                }

                @media(width < $bp-768px) {
                    margin: 0;
                }

                &:nth-child(even of .content-item) {
                    background-color: $color1;

                    a {
                        background-color: $color-black;
                        border-color: $color-black;

                        &:hover {
                            background-color: $color1;
                        }
                    }
                }

                &:nth-child(odd of .content-item) {
                    background-color: $color-black;

                    a {
                        background-color: $color1;
                        border-color: $color1;

                        &:hover {
                            background-color: $color-black;
                        }
                    }
                }

                p:not(:has(a)) {
                    font-size: 40px;
                    font-weight: bold;
                    text-transform: uppercase;
                    line-height: 1;
                    color: $color-white;
                    word-break: break-word;
                }

                p:has(a) {
                    margin-top: 30px;

                    a {
                        display: inline-block;
                        padding: 10px 20px;
                        border: 2px solid;
                        font-size: 16px;
                        text-transform: uppercase;
                        color: $color-white;
                    }
                }
            }

            :not(.content-item)+.content-item+ :not(.content-item) {

                @media(width < $bp-768px) {
                    margin: 0;
                }
            }
        }
    }
}