section.cases-row{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1.25rem;
    margin: 6.25rem 0;
    @media(width <= 767px){
        grid-template-columns: 1fr;
    }
    .case{
        display: grid;
        grid-template-rows: 1fr auto;
        background: $color-black;
        .content{
            padding: 3.75rem 1.875rem;
            display: flex;
            flex-direction: column;
            .title{
                margin: 0;
                font-size: 2.1875rem;
                font-weight: bold;
                color: $color-white;
                margin-bottom: 2.5rem;
            }
            .text{
                color: $color-white;
                margin-bottom: 1.875rem;
                a{
                    color: $color-white;
                    font-weight: bold;
                    &:hover{
                        text-decoration: underline;
                    }
                }
            }
            .link{
                display: inline-block;
                background: $color1;
                color: $color-white;
                font-weight: bold;
                padding: 0.625rem 1.875rem;
                translate: .3s;
                text-transform: uppercase;
                margin-top: auto;
                width: max-content;
                border-radius: 5px;
                &:hover{
                    background: $color-white;
                    color: $color1;
                }
            }
        }
        .image{
            display: block;
            height: 13.75rem;
            width: 100%;
            object-fit: cover;
        }
    }
}