section.shapes-row{
    position: relative;
    isolation: isolate;
    padding: 3.125rem 0 5.625rem;
    .image{
        display: block;
        position: absolute;
        width: 100vw;
        max-width: none;
        object-fit: cover;
        height: 100%;
        top: 0;
        margin-left: 50%;
        translate: -50%;
        z-index: -1;
        filter: brightness(.4);
    }
    .title{
        color: $color-white;
        text-align: center;
        font-weight: bold;
        font-size: 2.1875rem;
        margin: 0;
        margin-bottom: 3.125rem;
        &::after{
            content: "";
            display: block;
            width: 5ch;
            height: 3px;
            background-color: $color1;
            margin: 0 auto;
            margin-top: 0.9375rem;
        }
    }
    .shapes{
        .splide__arrow{
            opacity: 1;
            background: none;
            border: none;
            svg{
                fill: $color-white;
                transition: .3s;
                width: 1.1875rem;
                height: 1.875rem;
            }
            &:hover{
                svg{
                    fill: $color1;
                }
            }
        }
        .shape{
            display: flex;
            justify-content: center;
            .link{
                svg {
                    width: 100%;
                    path{
                        transition: .3s;
                    }
                }
                &:hover{
                    svg path{
                        fill: $color1 !important;
                    }
                }
            }
        }
    }
}