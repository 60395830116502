section.home-banner-row{
    $productItemsBottomOverflow: 4.375rem;

    position: relative;
    isolation: isolate;
    padding-top: 6.875rem;
    margin-bottom: $productItemsBottomOverflow;

    .background{
        position: absolute;
        top: 0;
        width: 100vw;
        margin-left: 50%;
        translate: -50%;
        height: 100%;
        z-index: -1;
        max-width: none;
        object-fit: cover;
        filter: brightness(.8);
    }

    .content{
        text-align: center;
        .title{
            color: $color-white;
            text-transform: none;
            &::after{
                content: "";
                display: block;
                height: 2px;
                width: 33%;
                background: $color1;
                margin: 0 auto;
                margin-top: 1.25rem;
            }
        }
    }

    .product-items{
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        gap: 0 4.375rem;
        translate: 0 $productItemsBottomOverflow;
        margin-top: calc(-1 * #{$productItemsBottomOverflow});
        @media(width <= 767px){
            grid-template-columns: repeat(6, 1fr);
            gap: 0 2.375rem;
        }
        .product-item{
            position: relative;
            isolation: isolate;
            display: flex;
            flex-direction: column;
            padding: 1.25rem;
            align-items: center;
            a{
                height: 100%;
                display: flex;
                flex-direction: column;
                img{
                    transition: .3s;
                    transform-origin: bottom;
                }
                .title{
                    padding: 1.25rem 0;
                    margin: 0;
                    margin-top: auto;
                }
            }
            &:hover {
                &::before{
                    background-color: $color1;
                }
                img{
                    scale: 1.2;
                }
            }
            @media(width <= 767px){
                grid-column: span 2;
                &:nth-child(4){
                    grid-column: 2/4;
                }
            }
            &::before{
                content: "";
                position: absolute;
                width: 100%;
                height: 70%;
                bottom: 0;
                background-color: $color-black;
                z-index: -1;
                border-radius: 5px;
                transition: .3s;
            }
            img{
                box-sizing: border-box;
            }
            .title{
                color: $color-white;
                text-align: center;
            }
        }
    }

}