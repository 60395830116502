// body.cms-page-view {
//     .page-wrapper {
//         position: relative;

//         &:after {
//             @include bg;
//             background-position-y: 100px;
//             width: 100%;
//             left: 0;
//         }

//         .cms-banner {
//             max-width: 128rem;
//             margin: 0 auto;
//             width: 100%;
//             height: 38rem;
//             @media(width < 780px){
//                 box-sizing: border-box;
//                 padding: 0;
//             }

//             p,
//             a {
//                 display: block;
//                 margin: 0;
//                 width: inherit;
//                 height: inherit;
//             }

//             img {
//                 object-fit: cover;
//                 width: inherit;
//                 height: inherit;
//             }
//         }

//         #maincontent {
//             @include transition;
//             @include box-shadow;
//             width: calc(100% - 4rem);
//             margin-bottom: 2rem;
//             padding: 7rem;
//             background-color: $color-white;
//             overflow: hidden;
//             @media(width < 780px){
//                 width: 100%;
//                 box-sizing: border-box;
//                 padding: 2rem;
//             }

//             .column.main {
//                 padding-bottom: 0;

//                 .action.primary,
//                 .red-button a {
//                     @include button;
//                     margin-top: 2rem;
//                 }

//                 .red-button {
//                     max-width: none;
//                     background-color: transparent;
//                     height: auto;
//                     text-align: inherit;
//                     margin: auto;
//                     transition: none;
//                 }

//                 table {
//                     border: 0;

//                     td {
//                         border: 0;
//                         width: auto;

//                         img {
//                             object-fit: cover;
//                         }
//                     }
//                 }
//             }

//         }
//     }
// }

body.cms-page-view{
    overflow-x: hidden;
    .column.main{
        padding: 0;
    }

    @import "../component/widgets/two-column-text-row";
    @import "../component/widgets/text-block-banner-row";
    @import "../component/widgets/gallery-row";
    @import "../component/widgets/bulletpoints-row";
    @import "../component/widgets/highlighted-products-row";
    @import "../component/widgets/cases-row";
    @import "../component/widgets/text-banner-row";

}