section.highlighted-products-row{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 3.125rem 0 6.25rem 0;
    .title{
        font-size: 2.1875rem;
        font-weight: bold;
        text-align: center;
        margin: 0;
        margin-top: 1.25rem;
        margin-bottom: 1.875rem;
        &::after{
            content: "";
            display: block;
            width: 5ch;
            height: 3px;
            background-color: $color1;
            margin: 0 auto;
            margin-top: 0.9375rem;
        }
    }
    .products{
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 3.125rem 1.25rem;
        grid-template-rows: 1fr auto;
        margin: 0;
        @media(width < 500px){
            grid-template-columns: 1fr;
        }
        .product{
            display: grid;
            gap: 1.25rem;
            grid-template-rows: subgrid;
            grid-row: span 2;
            .content{
                display: grid;
                grid-template-rows: 1fr auto;
                box-shadow: 0px 3px 6px rgba($color-black, 0.41);
                padding: 1.25rem;
                .image{
                    width: 100%;
                    align-self: center;
                    max-height: 200px;
                    object-fit: contain;
                }
                .name{
                    display: flex;
                    justify-content: space-between;
                    font-weight: bold;
                    margin: 0;
                    &::after{
                        content: "\f061";
                        font: var(--fa-font-regular);
                        align-self: end;
                    }
                }
            }
            .link{
                display: block;
                text-align: center;
                background: #5AA466;
                padding: 10px 0;
                color: $color-white;
                border-radius: 5px;
                transition: .3s;
                font-weight: bold;
                border: 2px solid transparent;
                &::after{
                    content: "\e4cf";
                    font: var(--fa-font-solid);
                    margin-left: 0.3125rem;
                }
                &:hover{
                    border: 2px solid #5AA466;
                    background-color: $color-white;
                    color: #5AA466;
                }
            }
        }
    }
    > .link{
        display: inline-block;
        background: $color1;
        padding: 5px 10px;
        color: $color-white;
        border-radius: 5px;
        font-weight: bold;
        transition: .3s;
        margin: 0 auto;
        margin-top: 1.875rem;
        border: 2px solid transparent;
        &:hover{
            background: $color-white;
            border: 2px solid $color1;
            color: $color1;
        }
    }
}