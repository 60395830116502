section.text-banner-row{
    position: relative;
    isolation: isolate;
    padding: 10.625rem 0 18.75rem;
    @media (width <= 767px){
        padding: 4rem 0;
    }
    .image{
        position: absolute;
        z-index: -1;
        width: 100vw;
        max-width: none;
        margin-left: 50%;
        translate: -50%;
        height: 100%;
        top: 0;
        filter: brightness(.6);
        object-fit: cover;
    }
    .content{
        width: 45%;
        margin-left: auto;
        @media (width <= 767px){
            width: 100%;
            margin: 0;
        }
        .title{
            color: $color-white;
            font-weight: bold;
            font-size: 2.1875rem;
            margin-top: 0;
            margin-bottom: 1.875rem;
            &::after{
                content: "";
                display: block;
                height: 3px;
                width: 6ch;
                background: $color1;
                margin-top: 1.25rem;
            }
        }
        .text{
            color: $color-white;
            a{
                font-weight: bold;
                color: inherit;
                transition: .3s;
                &:hover{
                    text-decoration: underline;
                }
            }
        }
    }
}