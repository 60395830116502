.page-wrapper {
    .page-header {
        background-color: $color-white;
        overflow: unset !important;
        @include box-shadow();
        z-index: 1;

        &:hover {
            box-shadow: 0.0625rem 0.0625rem 0.5rem rgba($color-black, .29);
        }

        .panel {
            &.wrapper {
                border: 0;
                background-color: $color3;

                .panel {
                    &.header {
                        display: flex;
                        justify-content: flex-end;

                        .top-contact {
                            float: left;
                            display: flex;
                            gap: .3125rem;
                            font-family: $font1;
                            font-size: $font-size1;
                            letter-spacing: 0;
                            color: $color4;
                            @include transition;

                            @media(width < 780px) {
                                display: grid;
                                width: 100%;
                                text-align: center;
                            }

                            p {
                                margin: 0;
                                @include transition;

                                a:hover {
                                    color: $color1;
                                    text-decoration: none;
                                }

                                &:has(a[href*=tel])::before{
                                    content: "\f095";
                                    font: var(--fa-font-solid);
                                    color: #1C1819;
                                    margin-right: 5px;
                                }
                                &:has(a[href*=mailto])::before{
                                    content: "\f0e0";
                                    font: var(--fa-font-solid);
                                    color: #1C1819;
                                    margin-right: 5px;
                                }

                                &:not(:last-child):after {
                                    @media(width >=780px) {
                                        content: '|';
                                    }

                                    font: inherit;
                                    margin-left: .3125rem;

                                }

                                // &:not(:last-child)::after{
                                //     content: '|';
                                //     font: inherit;
                                //     margin-left: 1rem;
                                // }
                            }
                        }

                        .switcher {
                            &.language {
                                margin: 0;
                                float: right;
                                color: $color4;
                                font-family: $font1;
                                font-size: $font-size1;
                                letter-spacing: 0;
                                margin-left: auto;

                                .switcher-options {
                                    display: flex;
                                    margin-left: .3125rem;
                                    gap: 5px;

                                    a {
                                        color: $color4;
                                        @include transition;

                                        &:hover {
                                            color: $color1;
                                            text-decoration: none;
                                        }
                                    }


                                    .toggle {
                                        pointer-events: none;

                                        &:after {
                                            content: '|';
                                            font: inherit;
                                        }

                                        strong {
                                            color: $color1;
                                            pointer-events: none;
                                            font-weight: $font-weight-bold;

                                            span {
                                                display: none;
                                            }
 

                                            &.view-storeview_nl,
                                            &.view-storeview_en {
                                                &:before {
                                                    font-family: $font1;
                                                    font-size: $font-size1;
                                                }
                                            }

                                            &.view-storeview_nl {
                                                &:before {
                                                    content: 'NL';
                                                }
                                            }

                                            &.view-storeview_en {
                                                &:before {
                                                    content: 'EN';
                                                }
                                            }

                                            &.view-storeview_de {
                                                &:before {
                                                    content: 'DE';
                                                }
                                            }
                                        }
                                    }

                                    .view-storeview_nl {
                                        &.switcher-option {
                                            
                                            a {
                                                font-size: 0;
                                                
                                                &:before {
                                                    content: 'NL';
                                                    font-family: $font1;
                                                    font-size: $font-size1;
                                                }
                                                &:after {
                                                    content: '|';
                                                    font-size: medium;
                                                    margin-left: 5px;
                                                }
                                            }
                                        }

                                    }

                                    .view-storeview_en {
                                        &.switcher-option {

                                            a {
                                                font-size: 0;

                                                &:before {
                                                    content: 'EN';
                                                    font-family: $font1;
                                                    font-size: $font-size1;
                                                }
                                                
                                                &:after {
                                                    content: '|';
                                                    font-size: medium;
                                                    margin-left: 5px;
                                                }
                                            }
                                        }

                                    }

                                    .view-storeview_de {
                                        &.switcher-option {

                                            a {
                                                font-size: 0;

                                                &:before {
                                                    content: 'DE';
                                                    font-family: $font1;
                                                    font-size: $font-size1;
                                                }

                                                &:after {
                                                    content: '|';
                                                    font-size: medium;
                                                    margin-left: 5px;
                                                }

                                            }
                                        }

                                    }

                                    .switcher-option:last-child a {

                                        &:after {
                                            display: none;
                                        }

                                    }

                                }
                            }
                        }

                    }
                }

                .header {
                    &.content {
                        display: flex;
                        display: space-between;
                    }
                }
            }
        }

        .header {
            &.content {
                background-color: $color-white;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 10px 20px;
                box-sizing: border-box;

                // LOGO BLOCK
                .logo {
                    width: 10rem;
                    max-width: unset;
                    margin: 0;

                    img {
                        width: 16rem;
                        height: auto;
                    }
                }

                // SEARCH BLOCK
                .block {
                    &.block-search {
                        order: 3;
                        width: fit-content;
                        padding-left: 0;

                        .block-content {
                            width: 25rem;
                            float: right;
                            position: absolute;
                            z-index: 4;
                            padding: 0;
                            bottom: 0;
                            right: 0;
                            transform: translate(-5%, 230%);
                            @include box-shadow;
                            display: none;

                            form {
                                .field {
                                    &.search {
                                        .control {
                                            padding: 0;

                                            input {
                                                padding: 0.625rem 2.5rem 0.625rem 0.625rem;
                                                border: 1px solid $color-black;
                                            }
                                        }
                                    }
                                }

                                .actions {
                                    .action.search {

                                        &::before {
                                            color: $color1 !important;
                                        }
                                    }
                                }
                            }

                            &:hover {
                                form {
                                    .actions {
                                        .action.search {
                                            &::before {
                                                animation: heart 1.5s infinite;
                                            }
                                        }
                                    }
                                }
                            }

                            //CLASSNAME ADD WITH JQUERY
                            &.visible {
                                display: block;
                            }
                        }

                        .search-button {
                            margin: 0;
                            border: 0;
                            font-size: 0;
                            background-color: transparent;
                            position: relative;
                            @include transition;

                            &::before {
                                content: '\f002';
                                font-weight: $font-weight-semi;
                                font-family: $font-awesome-pro;
                                font-size: 1.375rem;
                                position: absolute;
                                left: 0;
                                @include transition;
                            }

                            &:hover {
                                &:before {
                                    color: $color1;
                                    animation: heart 1.5s infinite;
                                }
                            }
                        }
                    }
                }

                // MINICART BLOCK
                .minicart-wrapper {
                    order: 4;
                    display: flex;
                    align-items: center;
                    padding: 0 1rem;
                    margin: 0;

                    a.action {
                        &.showcart {
                            @include transition();

                            &:before {
                                content: none;
                            }

                            i {
                                &:before {
                                    content: '\e4cf';
                                    font-family: $font-awesome-pro;
                                }
                                position: relative;
                                font-size: 1.25rem;
                            }

                            .counter {
                                &.qty {
                                    background-color: $color1;
                                    border-radius: 100%;
                                    position: absolute;
                                    // right: 0;
                                    // top: -0.9375rem;
                                    // width: 1.4375rem;
                                    // height: 1.4375rem;
                                    top: 50%;
                                    translate: -50% -50%;
                                    right: 0;
                                    aspect-ratio: 1;
                                    padding: 0;
                                    line-height: 1.5rem;
                                    font-size: 0.625rem;
                                    font-weight: bold;
                                    margin: 0;
                                    @include transition();

                                    &.empty {
                                        display: block;
                                    }
                                }
                            }

                            &:hover {

                                i {
                                    color: $color1;
                                    animation: heart 1.5s infinite;
                                }

                                .counter {
                                    &.qty {
                                        background-color: $color-black;
                                    }
                                }
                            }
                        }
                    }
                }

                // NAVIGATION BLOCK
                .sections {
                    &.nav-sections {
                        max-width: 56.5625rem;
                        margin: 0;
                        flex-shrink: 1;
                        margin-left: auto;
                        background: white;

                        @media(width < 780px) {
                            overflow-x: hidden;
                        }

                        .section-items {
                            .section-item-content {
                                .page-main {

                                    .rootmenu {
                                        background-color: transparent;
                                        position: static;

                                        .rootmenu-list {
                                            width: fit-content;
                                            border: 0;
                                            display: flex;
                                            flex-wrap: wrap;
                                            justify-content: center;
                                            gap: 15px;

                                            @media(width < 780px) {
                                                display: grid;
                                                width: 100%;
                                                justify-content: unset;

                                                >li>.megamenu {
                                                    box-shadow: none;
                                                }
                                            }

                                            >li {
                                                float: none;
                                                border: 0;
                                                display: flex;
                                                align-items: center;

                                                &.category-item {
                                                    a {
                                                        @include link-hover;
                                                        text-transform: none;
                                                        font-weight: normal;
                                                    }
                                                }
                                            }

                                            >li.active {
                                                a {
                                                    position: relative;
                                                    color: $color1;
                                                    border: 0 none;

                                                    &::before {
                                                        content: "";
                                                        left: 0;
                                                        bottom: 0;
                                                        right: 0;
                                                        background-color: $color-black;
                                                        height: 0.0625rem;
                                                        position: absolute;
                                                        @include transition(.4s);
                                                    }
                                                }
                                            }

                                            >li.custom-menus {
                                                position: relative;

                                                a {
                                                    @include link-hover;
                                                    font-weight: normal;
                                                    text-transform: none;
                                                    &[href*=contact] {
                                                        background: #5aa466;
                                                        color: $color-white;
                                                        padding: 8px 15px !important;
                                                        border-radius: 5px;

                                                        @media(width < 780px) {
                                                            //Bedankt, default rootways styling
                                                            color: $color-white !important;

                                                            &:hover {
                                                                color: $color-black !important;
                                                            }
                                                        }

                                                        &:hover {
                                                            background-color: $color-white;
                                                            color: $color-black;
                                                        }
                                                    }
                                                }

                                                &.active {
                                                    a {
                                                        position: relative;
                                                        color: $color1;
                                                        background-color: transparent;
                                                        border: 0 none;

                                                        &::before {
                                                            content: "";
                                                            left: 0;
                                                            bottom: 0;
                                                            right: 0;
                                                            background-color: $color-black;
                                                            height: 0.0625rem;
                                                            position: absolute;
                                                            @include transition(.4s);
                                                        }
                                                    }

                                                    ul {
                                                        li {
                                                            a {
                                                                color: $color-black;
                                                                @include transition;

                                                                &:hover {
                                                                    color: $color1;
                                                                }
                                                            }
                                                        }
                                                    }
                                                }

                                                ul {
                                                    top: 100%;
                                                    line-height: 0.9375rem;
                                                    opacity: 0;
                                                    pointer-events: none;
                                                    display: block;
                                                    position: absolute;
                                                    background-color: $color-white;
                                                    padding: 1.25rem;
                                                    text-align: left;
                                                    margin: 0;
                                                    box-shadow: 0 0.125rem 0.3125rem rgba($color-black, 0.2);
                                                    background: $color-white;
                                                    -webkit-box-shadow: 0 0.125rem 0.3125rem rgba($color-black, 0.2);
                                                    -moz-box-shadow: 0 0.125rem 0.3125rem rgba($color-black, 0.2);
                                                    max-width: none;
                                                    left: 0;
                                                    right: -3.125rem;
                                                    -webkit-transition: all .2s ease-in-out;
                                                    -o-transition: all .2s ease-in-out;
                                                    transition: all .2s ease-in-out;

                                                    li {
                                                        margin-bottom: 0.625rem;

                                                        a {
                                                            color: $color-black;
                                                            @include transition;

                                                            &::before {
                                                                display: none;
                                                            }

                                                            &:hover {
                                                                color: $color1;
                                                                text-decoration: none;
                                                            }
                                                        }
                                                    }
                                                }

                                                &:hover {
                                                    >ul {
                                                        opacity: 1;
                                                        pointer-events: auto;

                                                        @media(width < 780px) {
                                                            width: 100%;
                                                            z-index: 1;
                                                        }
                                                    }
                                                }
                                            }
                                        }

                                        >.rootmenu-list {
                                            >li {
                                                >a {
                                                    font-size: 1rem;
                                                    font-weight: $font-weight-semi;
                                                    padding: 0.3125rem 0.625rem;
                                                    padding-bottom: 0.3125rem !important;
                                                }

                                                >.megamenu {
                                                    top: 100%;
                                                    padding: 1.875rem 3.125rem;
                                                    z-index: 4;

                                                    @media(width < 780px) {
                                                        padding: 0;
                                                    }
                                                }
                                            }
                                        }
                                    }

                                    .megamenu.fullmenu {
                                        .root-sub-col-12 {
                                            display: -webkit-box;
                                            display: -ms-flexbox;
                                            display: flex;
                                            -webkit-box-orient: horizontal;
                                            -webkit-box-direction: normal;
                                            -ms-flex-flow: row wrap;
                                            flex-flow: row wrap;

                                            &::before {
                                                display: none;
                                            }

                                            &::after {
                                                display: none;
                                            }

                                            >div[class^="root-col"] {
                                                padding: 0 2.5rem 0 2.5rem;
                                                border-left: 1px solid #707070;
                                                margin: 0 0 2rem 0;

                                                @media(width >=780px) {
                                                    &:first-child {
                                                        border-left: 0;
                                                    }
                                                }
                                            }

                                            @media(width >=780px) {
                                                >div[class="clearfix"] {
                                                    + {
                                                        div[class^="root-col"] {
                                                            border-left: 0;
                                                        }
                                                    }
                                                }
                                            }

                                            .title {
                                                text-transform: uppercase;
                                                margin-bottom: 0.625rem;
                                                &.category-item a{
                                                    font-weight: bold;
                                                }

                                                a {
                                                    font-size: 1rem;
                                                    color: $color-black;

                                                    &::before {
                                                        display: none;
                                                    }

                                                    &:hover {
                                                        color: $color1;
                                                        text-decoration: none;
                                                    }
                                                }
                                            }

                                            ul {
                                                li {

                                                    a {
                                                        font-size: 1rem;
                                                        line-height: 1.25rem;
                                                        color: $color-black;

                                                        &::before {
                                                            display: none;
                                                        }

                                                        &:hover {
                                                            color: $color1;
                                                            text-decoration: none;
                                                        }
                                                    }
                                                }

                                                li.active {
                                                    a {
                                                        color: $color1;
                                                    }
                                                }
                                            }
                                        }
                                    }

                                    .sections.nav-sections.fixed {
                                        position: fixed;
                                        left: 0;
                                        right: 0;
                                        top: 0;
                                        background: $color-white;
                                        z-index: 999;

                                        .rootmenu {
                                            >.rootmenu-list {
                                                border-top-color: $color-white;
                                            }
                                        }
                                    }

                                    .nav-sections.fixed {
                                        .rootmenu {
                                            >.rootmenu-list {
                                                >li {
                                                    >.megamenu {
                                                        margin-top: -8px;
                                                    }
                                                }
                                            }
                                        }
                                    }

                                    .toggle-menu {
                                        display: none;
                                        position: absolute;
                                        top: 0;
                                        right: 0;
                                        cursor: pointer;
                                        z-index: 100;
                                        height: 45px;
                                        padding-left: 15px;

                                        &:after {
                                            content: '';
                                            float: right;
                                            display: block;
                                            height: 23px;
                                            width: 25px;
                                            margin-top: 7px;
                                            margin-right: 8px;
                                            background: url(../images/down.png) no-repeat top left;
                                            background-size: 25px;
                                        }
                                    }

                                    .open {
                                        >.toggle-menu {
                                            &:after {
                                                -webkit-transform: rotate(180deg);
                                                -moz-transform: rotate(180deg);
                                                -ms-transform: rotate(180deg);
                                                -o-transform: rotate(180deg);
                                                transform: rotate(180deg);
                                            }
                                        }
                                    }

                                }

                                @media(width >=780px) {
                                    .switcher {
                                        display: none;
                                    }
                                }

                            }
                        }
                    }
                }

                @media(width >=780px) {

                    // SWITCHER-OPTION
                    .view-storeview_en.switcher-option {
                        display: none;
                    }
                }

            }
        }

        &.fixed {
            position: fixed;
            left: 0;
            right: 0;
            top: 0;
            background: $color-white;
            z-index: 999;
        }
    }
}

@media(width < 780px) {
    .nav-sections {
        left: -100%;
        width: 90%;
    }

    .nav-open .nav-sections {
        left: 0;
    }
}