body.contact {
    .page-wrapper {
        position: relative;

        &:after {
            @include bg;
            background-position-y: 100px;
            width: 100%;
            left: 0;
        }

        #maincontent {
            @include transition;
            @include box-shadow;
            width: calc(100% - 4rem);
            margin-bottom: 2rem;
            padding: 7rem;
            background-color: $color-white;
            overflow: hidden;
            @media(width < 780px){
                margin: 0 auto 2rem;
                padding: 3rem;
                box-sizing: border-box;
                z-index: 1;
            }

            .columns .column.main {
                display: flex;
                padding-bottom: 0;

                #contact-form {
                    margin-right: 5rem;
                    min-width: auto;

                    >.fieldset {
                        margin-bottom: 0;

                        .field {
                            .label {
                                font-weight: normal;
                            }

                            input,
                            textarea {
                                @include transition;

                                &:active,
                                &:focus,
                                &:hover {
                                    border-color: $color1;
                                }
                            }
                        }
                        .field-recaptcha{
                            z-index: 1;
                            position: relative;
                        }
                    }

                    .warning {
                        color: $color1;
                        font-weight: bold;
                    }

                    .submit {
                        @include button;
                    }
                }

                .contact-info-container {
                    @media(width >= 780px){
                        width: 50%;
                    }

                    iframe {
                        margin-top: 30px;
                    }

                    h3,
                    p {
                        margin: 0;
                        font-weight: normal;
                    }

                    ul {
                        width: 100%;
                        display: flex;
                        list-style: none;
                        gap: 20px;
                        padding: 0;
                        margin-top: 26px;
                        @media(width <= 640px){
                            flex-direction: column;
                            align-items: center;
                        }

                        li {
                            @include button;
                            margin-bottom: 0;

                            a {
                                color: $color-white;
                            }
                        }
                    }
                }
            }
        }
    }
}