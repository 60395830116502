.minicart-wrapper {
	order: 4;
	display: flex;
	padding: 0 0.625rem;
	margin: 0;

	.action.showcart {
		display: block;
		margin-bottom: 0;

		&:before {
			content: none;
		}

		.counter.qty.empty {
			display: block;
		}

		&.active {
			display: block;

			&:before {
				content: none;
			}
		}
	}

	.block-minicart {
		padding-bottom: 0;

		#minicart-content-wrapper {
			.block-content {

				// TOP BLOCK
				.items-total {
					float: none;

					.count {
						margin-right: 0.3125rem;
					}
				}

				.actions {
					.primary {
						a.action.primary.viewcart.checkout {
							@include button;
							margin-top: 0;

								span.text {
									&::after {
										content: '\f15b';
										font-family: $font-awesome-pro;
										margin-left: 0.3125rem;
									}
							}
						}
					}
				}

				.subtitle {
					margin-bottom: 0.625rem;
				}

				//END TOP BLOCK

				// BOTTOM BLOCK
				.minicart-items-wrapper {
					#mini-cart {

						.item {
							&.product-item {

								.product {
									display: flex;

									a {
										height: 100%;
										margin: auto 0;
									}

									.product-item-details {
										width: 100%;
										padding-left: 2rem;

										.product-item-name {
											a {
												color: $color-black;
												font-size: 0.9375rem;
												font-weight: 700;
											}
										}

										.product.actions {
											float: none;
											clear: both;
											text-align: left;
											margin: 0;
										}
									}
								}

							}
						}

					}
				}

				// END BOTTOM BLOCK
			}
		}
	}

	#top-cart-btn-checkout{
		font-size: 1rem;
	}
}



// OUDE CODE

.minicart-items {
	.product-item-name {
		a {
			color: $color4;
			font-size: 0.9375rem;
			font-weight: 700;
		}
	}

	.product-item {
		.qty {
			label {
				display: none;
			}

			margin-top: 0;
			display: inline;
			float: right;
			cursor: default;

			.item-qty {
				border: 0 none;
				width: auto;
				max-width: 35px;
				margin: 0;
				line-height: 1;
				padding: 0;
				text-align: right;
				height: auto;
				pointer-events: none;
				cursor: default;
			}

			&::after {
				content: "x";
			}
		}

		.price-container {
			display: inline;

			.price-excluding-tax {
				display: inline;
				font-size: 1rem;
			}
		}
	}

	.product-item-details {
		.price {
			font-size: 0.9375rem;
		}
	}

	.action.edit {
		margin-bottom: 0;

		&::before {
			font-family: $font-awesome;
			content: "\f040";
			font-size: 0.9375rem;
			line-height: 1;
			color: $color4;
			-webkit-transition: color .2s ease;
			transition: color .2s ease;
		}

		&:hover {
			&::before {
				color: $color1;
			}
		}
	}

	.action.delete {
		margin-bottom: 0;

		&::before {
			font-family: $font-awesome;
			content: "\f040";
			font-size: 0.9375rem;
			line-height: 1;
			color: $color4;
			-webkit-transition: color .2s ease;
			transition: color .2s ease;
			content: "\f014";
			margin-left: 10px;
		}

		&:hover {
			&::before {
				color: $color1;
			}
		}
	}
}

.cart.table-wrapper {
	.product-item-name {
		font-weight: 700;
	}

	.item-actions {
		.actions-toolbar {
			text-align: right;

			.action {
				border: 0 none;
				background: none;
				color: $color4;
				padding: 0;

				span {
					display: none;
				}

				&::before {
					font-family: $font-awesome;
					content: "\f040";
					font-size: 0.9375rem;
					font-weight: 400;
					line-height: 1;
					color: $color4;
					-webkit-transition: color .2s ease;
					transition: color .2s ease;
				}

				&:hover {
					&::before {
						color: $color1;
					}
				}
			}

			.action.action-delete {
				&::before {
					content: "\f014";
				}
			}
		}
	}
}

.cart-container {
	.form-cart {
		box-sizing: border-box;
		-webkit-box-sizing: border-box;

		.actions {
			display: flex;
			.action {
				display: flex;
				align-items: center;
				border-radius: 0;
				background-color: $color2;
				border: 0 none;
				margin-bottom: 0;
				&.continue{
					margin-right: auto;
				}
				&:hover {
					border: 0 none;
				}
			}
		}
	}
}

.offer-summary {
	width: 22.5%;
	padding: 0;
	background: none;

	>.title {
		padding: 10px;
		margin: 0;
		font-size: 1rem;
		background-color: $color2;
		border: 1px solid #E3E3E3;
		color: $color-white;
		font-weight: 700;
		background-color: $color1;
		border: 1px solid #E1201B;
	}

	.offer-form {
		fieldset {
			margin-bottom: 0;
		}
	}

	.block {
		.fieldset {
			margin-left: 0;
		}
	}

	.fieldset {
		>.field.select {
			position: relative;

			&::after {
				-webkit-font-smoothing: antialiased;
				-moz-osx-font-smoothing: grayscale;
				line-height: 1;
				color: $color6;
				content: '\f078';
				font-family: $font-awesome-pro;
				position: absolute;
				bottom: 7px;
				right: 7px;
				pointer-events: none;
			}
		}
	}

	.block-offer-form {
		form {

			.fieldset {
				>.field {
					>.label {
						font-size: 18px;
					}
				}
			}
		}
	}
}

.cart-summary {
	width: 22.5%;
	padding: 0;
	background: none;

	>.title {
		padding: 10px;
		margin: 0;
		font-size: 1rem;
		background-color: $color2;
		border: 1px solid #E3E3E3;
		color: $color-white;
		font-weight: 700;
	}

	.block-offer-form {
		padding: 10px;
		border: 1px solid #E3E3E3;
		border-top: 0;
	}

	.block {
		padding: 10px;
		border: 1px solid #E3E3E3;
		border-top: 0;

		>.title {
			border-top: 0;
			padding: 0;
			margin: 0;

			&::after {
				position: static;
				top: auto;
				right: auto;
				line-height: 1;
			}
		}

		.fieldset {
			margin-left: 0;
		}
	}

	.block.active {
		>.title {
			&::after {
				position: static;
				top: auto;
				right: auto;
				line-height: 1;
			}
		}
	}
}

.column.main {
	.cart-summary {
		.block {
			>.title {
				strong {
					font-size: 0.625rem;
					margin: 0;
				}
			}
		}
	}
}

.block-offer-form {
	padding: 10px;
	border: 1px solid #E3E3E3;
	border-top: 0 none;
}

.cart-totals {
	padding: 10px;
	border: 1px solid #E3E3E3;
	border-top: 0 none;

	.mark {
		padding-left: 0;
	}

	.amount {
		padding-right: 0;
	}
}

.block-minicart {
	.subtitle {
		display: block;
		margin: 0 auto;
		text-align: center;
	}
}

* {
	.cart-empty {
		a {
			text-decoration: underline;
		}
	}
}