section.bulletpoints-row{

    margin: 6.25rem 0;
    padding: 3.75rem;
    box-shadow: 0px 3px 6px rgba($color-black, 0.41);

    .title{
        font-weight: bold;
        font-size: 2.1875rem;
        margin: 0;
    } 

    .bulletpoints{
        margin-top: 3.75rem;
        margin-bottom: 2.5rem;
        ul{
            list-style: none;
            padding: 0;
            margin: 0;
            columns: 2;
            li{
                margin: 0;
                &::before{
                    content: "\f00c";
                    font: var(--fa-font-solid);
                    margin-right: 2.5rem;
                    color: $color1;
                }
                &+li{
                    margin-top: 0.625rem;
                }
            }
        }
    }

}