section.two-column-text-row{
    @include bg3;
    padding: 60px 0;
    margin-left: 50%;
    translate: -50%;
    width: 100vw;
    .full-row{
        box-sizing: border-box;
        margin-left: auto;
        margin-right: auto;
        max-width: 1280px;
        padding-left: 20px;
        padding-right: 20px;
        width: 100%;
        .container{
            padding: 35px;
            border-radius: 5px;
            box-shadow: 0px 3px 6px rgba($color-black, .41);
            background-color: $color-white;
            .title{
                display: inline-block;
                font-weight: bold;
                margin: 0;
                font-size: 2.1875rem;
                &::after{
                    content: "";
                    display: block;
                    background: $color1;
                    height: 3px;
                    width: 50%;
                    margin-top: 10px;
                }
            }
            .content{
                columns: 2;
                margin-top: 20px;
                @media(width <= 767px) {
                    columns: unset;
                }
                *{
                    margin: 0;
                }
                ul{
                    padding: 0;
                    list-style: none;
                    li{
                        &::before{
                            content: "•";
                            color: $color1;
                            margin-right: 5px;
                        }
                    }
                }
                .link{
                    display: inline-block;
                    background: $color1;
                    padding: 5px 10px;
                    color: $color-white;
                    border-radius: 5px;
                    font-weight: bold;
                    margin-top: 15px;
                    transition: .3s;
                    border: 2px solid transparent;
                    &:hover{
                        border: 2px solid $color1;
                        background: $color-white;
                        color: $color1;
                    }
                }
            }
        }
    }
}