body:is(.mpblog-category-view, .mpblog-post-view){
    div.columns{
        display: grid;
        grid-template-columns: 1fr 12.5rem;
        gap: 1.25rem;
        .column.main{
            grid-column: 1;
        }
        .sidebar-main{
            width: 100%;
        }
    }
}

#mpblog-list-container{
    .mp-post-info{
        font-size: 1rem;
    }
    .post-item-list{
        font-size: 1rem;
        .post-link-title{
            font-size: 1.875rem;
            font-weight: bold;
            text-transform: uppercase;
            position: relative;
            &::after{
                content: '';
                position: absolute;
                left: 0;
                right: 0;
                bottom: -5px;
                height: 3px;
                background-color: #E1201B;
                width: 60%;
                max-width: 248px;
            }
        }
    }
}

.mpblog-post-view{
    .page-title{
    margin-top: 20px;
        margin-bottom: 0;
    }
    .post-post_content{
        h3{
            margin-top: 0;
        }
        .mp-post-info{
            font-size: 1rem;
        }
    }
}

.mp-sidebar{
    #tab-label-popular, #tab-label-mostview{
        #tab-label-popular-title, #tab-label-mostview-title{
            font-size: 1rem;
        }
        &[aria-expanded=true] :is(#tab-label-popular-title, #tab-label-mostview-title){
            color: $color1;
        }
    }
    .content{
        border: 1px solid gainsboro !important;
    }
}

@import "../component/widgets/text-block-banner-row";

.text-block-banner-row{
    grid-column: span 2;
}