body.catalog-product-view{
    overflow-x: hidden;
    #maincontent{
        margin-top: 2.5rem;
        margin-bottom: 2.5rem;
        .page-title-wrapper.product{
            text-align: center;
            box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.41);
            margin-bottom: 1.875rem;
            padding: 1.25rem 0;
            .page-title{
                margin: 0;
            }
        }
        .column.main{
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 1.25rem;
            padding: 0;
            @media(width <= 767px){
                grid-template-columns: 1fr;
            }
            .product.media{
                float: none;
                width: 100%;
                .galleries{
                    display: grid;
                    gap: 2.5rem;
                    .gallery{
                        box-shadow: 0px 3px 6px rgba($color-black, .41);
                        .splide__slide{
                            height: 31.25rem;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            position: relative;
                            img{
                                width: 100%;
                                height: 100%;
                                object-fit: contain;
                            }
                            .label{
                                position: absolute;
                                background-color: #5aa466;
                                color: $color-white;
                                padding: 0.125rem 1.25rem; 
                                bottom: 2.1875rem;
                                left: 0;
                                font-size: 1.5625rem;
                                font-weight: bold;
                            }
                        }
                    }
                    .thumbnails{
                        display: grid;
                        .splide__slide{
                            position: relative;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            &.is-active{
                                border: 2px solid $color1;
                            }
                            &::before{
                                content: "";
                                display: block;
                                position: absolute;
                                width: 100%;
                                height: 100%;
                                box-shadow: 0px 3px 6px rgba($color-black, .41);
                            }
                            margin-bottom: 0.625rem;
                        }
                    }
                }
            }
            .product-info-main{
                width: 100%;
                height: max-content;
                padding: 1.875rem 1.875rem 3.75rem 1.875rem;
                box-shadow: 0px 3px 6px rgba($color-black, .41);
                box-sizing: border-box;
                @media(width <= 767px){
                    grid-column: 1;
                }
                .product-title-description{
                    font-weight: bold;
                    font-size: 2.1875rem;
                    line-height: 1;
                    margin-bottom: 1.25rem;
                }
                .additional-attributes-wrapper{
                    h2{
                        font-weight: bold;
                        font-size: 1.375rem;
                    }
                    #product-attribute-specs-table{
                        th,td{
                            padding: 0;
                        }
                        .label{
                            font-size: 1.25rem;
                            font-weight: 600;
                        }
                        .data{
                            font-size: 1.25rem;
                        }
                    }
                }
                .product-add-form{
                    .box-tocart{
                        width: 100%;
                        margin-bottom: 0;
                        .fieldset{
                            display: grid;
                            margin-bottom: 0;
                            .field.qty {
                                width: 100%;
                                padding-right: 0;
                                margin: 0;
                                margin-top: 2.5rem;

                                .label {
                                    font-size: 1.375rem;
                                    margin-bottom: 0.625rem;
                                }

                                .qty-select-control {
                                    position: relative;

                                    &:after {
                                        content: "\f078";
                                        font-family: $font-awesome-pro;
                                        right: 1rem;
                                        height: 100%;
                                        top: 25%;
                                        position: absolute;
                                        font-weight: bold;
                                        pointer-events: none;
                                    }

                                    .qty-select {
                                        background-color: $color6;
                                        border-color: $color6;
                                        height: 2.8125rem;
                                        width: 100%;
                                        font-size: 1rem;
                                    }
                                }

                                .number-container {
                                    display: none;

                                    .cancel-input {
                                        color: $color1;
                                        font-size: 1rem;
                                        margin-left: 1rem;
                                        cursor: pointer;
                                    }
                                }
                            }
                            .actions{
                                padding: 0;
                                margin-right: auto;
                                #product-addtocart-button{
                                    margin: 0;
                                    margin-top: 1.875rem;
                                    background: #5aa466;
                                    border: 2px solid transparent;
                                    font-size: 1rem;
                                    padding: 0.625rem 3.125rem;
                                    transition: .3s;
                                    &:hover{
                                        border: 2px solid #5aa466;
                                        background: $color-white;
                                        color: #5aa466; 
                                    }
                                    span::after{
                                        content: "\e4cf";
                                        font: var(--fa-font-solid);
                                        margin-left: 1.25rem;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .bottom_text{
                grid-column: span 2;
            }
        }
    }
}

.products-related.products {
    .product-items {
        display: grid;
        // grid-template-columns: repeat(auto-fill, minmax(310px, 1fr));

        @media (width > $bp-1024px) {
            grid-template-columns: repeat(4, 1fr);
        }

        @media(width < $bp-768px) {
            grid-template-columns: repeat(2, 1fr);
        }

        @media(width < $bp-480px) {
            display: block;
        }

        grid-auto-flow: dense;
        gap: 35px 20px;
        width: 100%;

        .product-item {
            margin: 0;
            width: 100%;

            .product-item-info {
                display: grid;
                height: 100%;
                width: 100%;
                gap: 20px;

                .info-top {
                    display: grid;
                    grid-template-columns: 1fr auto;
                    position: relative;
                    background-color: $color-white;
                    box-shadow: 0 3px 6px rgba($color-black, .16);
                    padding: 20px;

                    .product-item-photo{
                        grid-column: span 2;
                    }

                    .product-item-name {
                        font-weight: bold;
                        font-size: 19px;
                        color: $color-black;
                        margin: 0;
                    }

                    .product-item-description {
                        margin: 0;
                    }

                    .product-item-link {
                        color: $color-black;
                        font-weight: bold;
                    }

                    .action.more {
                        font-size: 0;
                        &::after{
                            content: "\f061";
                            font: var(--fa-font-regular);
                            align-self: end;
                            font-size: 22px;
                        }
                    }

                    .product-image-container {
                        width: 100% !important;
                    }
                }

                .info-bottom {
                    background-color: $color-white;
                    box-shadow: 0 3px 6px rgba($color-black, .16);

                    .actions-primary {
                        width: 100%;

                        .action.tocart.primary {
                            width: 100%;
                            margin: 0;
                            padding: 15px x0;
                            text-transform: uppercase;
                            font-size: 16px;
                            background: #5AA466;
                            border-color: #5AA466;

                            &:hover{
                                background: white;
                                color: #5AA466;
                            }

                            &::after{
                                content: "\e4cf";
                                font: var(--fa-font-solid);
                                margin-left: 0.3125rem;
                            }
                            // &::after {
                            //     content: "\f07a";
                            //     font-family: $font-awesome-pro;
                            //     margin-left: 5px;
                            // }
                        }
                    }
                }
            }
        }

        .content-item {
            display: flex;
            justify-content: center;
            flex-direction: column;
            padding: 50px 40px;
            margin: 30px 0;
            grid-column: span 2;

            &.content-item-5 {
                display: none;
            }

            @media(width < $bp-768px) {
                margin: 0;
            }

            &:nth-child(even of .content-item) {
                background-color: $color1;

                a {
                    background-color: $color-black;
                    border-color: $color-black;

                    &:hover {
                        background-color: $color1;
                    }
                }
            }

            &:nth-child(odd of .content-item) {
                background-color: $color-black;

                a {
                    background-color: $color1;
                    border-color: $color1;

                    &:hover {
                        background-color: $color-black;
                    }
                }
            }

            p:not(:has(a)) {
                font-size: 40px;
                font-weight: bold;
                text-transform: uppercase;
                line-height: 1;
                color: $color-white;
                word-break: break-word;
            }

            p:has(a) {
                margin-top: 30px;

                a {
                    display: inline-block;
                    padding: 10px 20px;
                    border: 2px solid;
                    font-size: 16px;
                    text-transform: uppercase;
                    color: $color-white;
                }
            }
        }

        :not(.content-item)+.content-item+ :not(.content-item) {

            @media(width < $bp-768px) {
                margin: 0;
            }
        }
    }
}

.products-related.products {
    .product-items {
        display: grid;
        // grid-template-columns: repeat(auto-fill, minmax(310px, 1fr));

        @media (width > $bp-1024px) {
            grid-template-columns: repeat(4, 1fr);
        }

        @media(width < $bp-768px) {
            grid-template-columns: repeat(2, 1fr);
        }

        @media(width < $bp-480px) {
            display: block;
        }

        grid-auto-flow: dense;
        gap: 35px 20px;
        width: 100%;

        .product-item {
            margin: 0;
            width: 100%;

            .product-item-info {
                display: grid;
                height: 100%;
                width: 100%;
                gap: 20px;

                .info-top {
                    position: relative;
                    background-color: $color-white;
                    box-shadow: 0 3px 6px rgba($color-black, .16);
                    padding: 20px;

                    .product-item-name {
                        font-weight: bold;
                        font-size: 19px;
                        color: $color-black;
                    }

                    .product-item-link {
                        color: $color-black;
                        font-weight: bold;
                    }

                    .action.more {
                        position: absolute;
                        right: 10px;
                        bottom: 10px;
                        line-height: 1;

                        i {
                            font-size: 22px;
                        }
                    }

                    .product-image-container {
                        width: 100% !important;
                    }
                }

                .info-bottom {
                    background-color: $color-white;
                    box-shadow: 0 3px 6px rgba($color-black, .16);

                    .actions {
                        margin: 0;

                        .actions-primary {
                            width: 100%;

                            .action.tocart.primary {
                                width: 100%;
                                margin: 0;
                                padding: 15px x0;
                                text-transform: uppercase;
                                font-size: 16px;
                                background: #5AA466;
                                border-color: #5AA466;

                                &:hover{
                                    background: white;
                                    color: #5AA466;
                                }

                                &::after{
                                    content: "\e4cf";
                                    font: var(--fa-font-solid);
                                    margin-left: 0.3125rem;
                                }
                                // &::after {
                                //     content: "\f07a";
                                //     font-family: $font-awesome-pro;
                                //     margin-left: 5px;
                                // }
                            }
                        }
                    }
                }
            }
        }

        .content-item {
            display: flex;
            justify-content: center;
            flex-direction: column;
            padding: 50px 40px;
            margin: 30px 0;
            grid-column: span 2;

            &.content-item-5 {
                display: none;
            }

            @media(width < $bp-768px) {
                margin: 0;
            }

            &:nth-child(even of .content-item) {
                background-color: $color1;

                a {
                    background-color: $color-black;
                    border-color: $color-black;

                    &:hover {
                        background-color: $color1;
                    }
                }
            }

            &:nth-child(odd of .content-item) {
                background-color: $color-black;

                a {
                    background-color: $color1;
                    border-color: $color1;

                    &:hover {
                        background-color: $color-black;
                    }
                }
            }

            p:not(:has(a)) {
                font-size: 40px;
                font-weight: bold;
                text-transform: uppercase;
                line-height: 1;
                color: $color-white;
                word-break: break-word;
            }

            p:has(a) {
                margin-top: 30px;

                a {
                    display: inline-block;
                    padding: 10px 20px;
                    border: 2px solid;
                    font-size: 16px;
                    text-transform: uppercase;
                    color: $color-white;
                }
            }
        }

        :not(.content-item)+.content-item+ :not(.content-item) {

            @media(width < $bp-768px) {
                margin: 0;
            }
        }
    }
}

@import "../component/widgets/text-slider-row";
@import "../component/widgets/highlighted-products-row";