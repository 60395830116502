section.gallery-row{
    margin: 6.25rem 0;
    .gallery-items{
        display: grid;
        grid-auto-rows: 13.75rem;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 8px;
        @media(width <= 767px){
            grid-auto-rows: 8.75rem;
        }
        .gallery-item{
            &:nth-child(5n-4){
                grid-row: span 3;
            }
            &:nth-child(5n-2){
                grid-row: span 2;
            }
            &:nth-child(5n-1){
                grid-row: span 2;
            }
            .image{
                display: block;
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
        }
    }
}