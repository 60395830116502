section.text-slider-row{
    width: 100vw;
    margin-left: 50%;
    translate: -50%;
    .splide{
        &:not(.is-initialized) .splide__list{
            display: flex;
            .splide__slide{
                width: 100%;
                flex-shrink: 0;
            }
        }
        .splide__track{
            ul.splide__list{
                margin: 0;
                padding: 0;
                li.splide__slide{
                    margin: 0;
                    article.text-slide{
                        position: relative;
                        isolation: isolate;
                        height: 100%;
                        .image{
                            position: absolute;
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            z-index: -1;
                            @media(width <= 767px){
                                filter: brightness(.5);
                            }
                        }
                        .full-row{
                            box-sizing: border-box;
                            margin-left: auto;
                            margin-right: auto;
                            max-width: 1280px;
                            padding-left: 20px;
                            padding-right: 20px;
                            .content{
                                margin-left: auto;
                                width: 50%;
                                padding: 6.25rem 0;
                                @media(width <= 767px){
                                    margin-left: 0;
                                    width: 100%;
                                }
                                .title{
                                    margin: 0;
                                    color: $color-white;
                                    font-weight: bold;
                                    font-size: 2.1875rem;
                                    display: inline-block;
                                    &::after{
                                        content: "";
                                        display: block;
                                        font: inherit;
                                        height: 3px;
                                        width: 4ch;
                                        background: $color1;
                                        margin: 20px 0;
                                    }
                                }
                                .text{
                                    color: $color-white;
                                }
                                .link{
                                    display: inline-block;
                                    background: $color1;
                                    padding: 5px 10px;
                                    color: $color-white;
                                    border-radius: 5px;
                                    font-weight: bold;
                                    margin-top: 15px;
                                    transition: .3s;
                                    border-radius: 5px;
                                    &:hover{
                                        background: $color-white;
                                        color: $color1;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}