// MIXINS
@mixin box-shadow {
    box-shadow: 0.1rem 0.1rem 0.8rem rgba($color-black, .29);
    @include transition();

    &:hover {
        box-shadow: .2rem .2rem 1rem rgba($color-black, .60);
    }
}

@mixin transition($duration: .2s) {
    -webkit-transition: all $duration ease-in-out;
    transition: all $duration ease-in-out;
}

@mixin bg {
    background-image: url('../images/background-home.png');
    content: "";
    position: absolute;
    background-size: cover;
    height: 100%;
    width: 170%;
    left: -30%;
    top: 0;
    z-index: -1;
    background-position-y: 0;
}

@mixin bg2 {
    background-image: url('../images/background-contact.jpg');
    background-size: cover;
    background-position-y: 0;
    position: relative;
    background-attachment: fixed;

    &::before {
        content: '';
        width: 100%;
        height: 100%;
        background-color: $color-white;
        position: absolute;
        top: 0;
        opacity: .5;
    }
}

@mixin bg3{
    background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1NTMuNzY3IiBoZWlnaHQ9IjQ3Mi4wNSIgdmlld0JveD0iLTAuMDE3IC0wLjAxNyA1NTMuNzY3IDQ3Mi4wNSI+PHBhdGggZD0iTTAgMGgyNDkuNTJsMzA0LjIxNiA0NzIuMDEyaC0yNTIuNjNMMCAwWiIgZmlsbD0iI2Y3ZjdmNiIgZmlsbC1ydWxlPSJldmVub2RkIiBkYXRhLW5hbWU9IlBhdGggNTYiLz48L3N2Zz4=");
    background-size: contain;
}

// RED BUTTON
@mixin button {
    font-family: $font1;
    background-color: $color1;
    border: 2px solid $color1;
    color: $color-white;
    font-size: $font-size1;
    font-weight: $font-weight-bold;
    text-transform: uppercase;
    // padding: 1.5rem 4rem;
    display: inline-block;
    overflow: hidden;
    position: relative;
    border-radius: 5px;
    @include transition();

    &:before {
        content: " ";
        display: block;
        width: 20rem;
        height: 8rem;
        background: $color-white;
        opacity: 0.8;
        position: absolute;
        top: -1rem;
        left: -20rem;
        transform: rotate(-45deg);
        transition: all .4s ease-in-out;
    }

    &:hover {
        color: $color-black !important;
        background-color: $color2 !important;
        border: 2px solid $color2 !important;

        &:before {
            margin-left: 300%;
        }
    }
}

// LINK HOVER EFFECT
@mixin link-hover {
    color: $color4;
    position: relative;
    @include transition();

    &::before {
        content: "";
        left: 0;
        bottom: 0;
        right: 100%;
        background-color: $color-black;
        height: 1px;
        position: absolute;
        @include transition(.4s);
    }

    &:hover {
        color: $color1;

        &::before {
            right: 0;
        }
    }
}

// BLOCK WITH RED BACKGROUND
@mixin block-hover {
    font-family: $font1;
    background-color: $color1;
    border: .2rem solid $color1;
    color: $color-white;
    font-size: $font-size1;
    display: inline-block;
    overflow: hidden;
    position: relative;
    @include transition();

    &:before {
        content: '\f054';
        font-size: 90rem;
        font-style: normal;
        color: $color-white;
        font-weight: $font-weight-bolder;
        font-family: $font-awesome-pro;
        display: block;
        // width: 100px;
        // height: 250px;
        // background: $color-white;
        opacity: 0.8;
        position: absolute;
        top: 50%;
        left: -130rem;
        transform: translate(0, -50%);
        // transform: rotate(45deg);
        @include transition(.5s);
    }

    &:hover {
        color: $color-black !important;
        background-color: $color2 !important;
        border: .2rem solid $color2 !important;

        &:before {
            margin-left: 275%;
        }
    }
}

@mixin magento-hide {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: .1rem;
    margin: -.1rem;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: .1rem;
}

@mixin magento-show {
    clip: auto;
    height: auto;
    width: auto;
    margin: 0;
    overflow: visible;
    position: static;
}