section.text-block-banner-row{
    isolation: isolate;
    margin-left: 50%;
    translate: -50%;
    width: 100vw;
    position: relative;
    padding-top: 12.5rem;
    margin-bottom: 8.125rem;

    @media(width <= 767px){
        padding: 0 1.25rem;
        padding-top: 7rem;
    }

    .background-image{
        display: block;
        position: absolute;
        top: 0;
        width: 100%;
        height: 21.875rem;
        object-fit: cover;
        opacity: .3;
        z-index: -1;
    }
    .full-row{
        box-sizing: border-box;
        margin: 0 auto;
        max-width: 1280px;
        width: 100%;
        padding: 0 1.25rem;
        .wrapper{
            display: grid;
            grid-template-columns: 55% 45%;
            box-shadow: 0px 3px 6px rgba($color-black, 0.41);
            @media(width <= 767px){
                grid-template-columns: 1fr;
            }
            .content{
                display: flex;
                flex-direction: column;
                background: $color-white;
                padding: 3.125rem;
                .title{
                    text-transform: none;
                    margin-bottom: 1.25rem;
                    &::after{
                        content: "";
                        display: block;
                        height: 3px;
                        width: 6ch;
                        background: $color1;
                        margin-top: 1.25rem;
                    }
                }
                .text{
                    margin-bottom: 1.25rem;
                    p{
                        margin: 0;
                    }
                }
                .link{
                    display: inline-block;
                    background: $color1;
                    padding: 0.625rem 1.25rem;
                    color: $color-white;
                    font-weight: bold;
                    transition: .3s;
                    margin-top: auto;
                    width: max-content;
                    border: 2px solid transparent;
                    border-radius: 5px;
                    &:hover{
                        background: $color-white;
                        border: 2px solid $color1;
                        color: $color1;
                    }
                }
            }
            .image{
                height: 100%;
                object-fit: cover;
                @media(width <= 767px){
                    display: none;
                }
            }
        }
    }
}