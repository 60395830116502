/* Imports ============================================================= */
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,300,400,600,700,900');
@import url('https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css');
/* Default styling ===================================================== */

:root {

  --min-fs: .6875;
  --max-fs: 1;
  --min-vw: 18.75;
  --max-vw: 77.5;

  --min-fs-rem: var(--min-fs) * 1rem;
  --max-fs-rem: var(--max-fs) * 1rem;
  --min-vw-rem: var(--min-vw) * 1rem;

  --slope: (var(--max-fs) - var(--min-fs)) * (100vw - var(--min-vw-rem)) / (var(--max-vw) - var(--min-vw));

  font-size: clamp(var(--min-fs-rem), var(--min-fs-rem) + var(--slope), var(--max-fs-rem));
}

.page-title{
	margin-top: 40px;
}

html {
	scroll-behavior: smooth;
}

*{
	font-size: inherit;
}

body {
	color: $color4;
	font-family: 'Source Sans Pro', sans-serif;
	font-style: normal;
	font-weight: 400;
	line-height: 1.4;
	font-size: 1rem;

	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

}

b,
strong {
	font-weight: 700;
}

em,
i {
	font-style: italic;
}

mark {
	background: $color2;
	color: $color4;
}

hr {
	border: 0;
	border-top: 1px solid $color4;
	margin-bottom: 0.625rem;
	margin-top: 0.625rem;
}

h1 {
	font: 700 2.1875 'Source Sans Pro', sans-serif;
	margin-top: 0rem;
	margin-bottom: 3rem;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	position: relative;
	display: inline-block;
	clear: both;
	font-weight: bold;
	font-size: 2.1875rem;
}

#block-upsell-heading,
#block-related-heading {
	line-height: 5.5rem;
	position: relative;
	text-transform: uppercase;
	font-weight: bold;
	font-size: 2.1875rem;
	display: inline-block;
	clear: both;
	margin: 0;
}

h3 {
	font-weight: 600;
	line-height: 1.1;
	font-size: 1.25rem;
	margin-top: 2.5rem;
	margin-bottom: 2rem;
}

h4 {
	font-weight: 600;
	line-height: 1.1;
	font-size: 1.8rem;
	margin-top: 1.5rem;
	margin-bottom: 0.5rem;
}

h5 {
	font-weight: 600;
	line-height: 1.1;
	font-size: 1.2rem;
	margin-top: 2rem;
	margin-bottom: 2rem;
}

h6 {
	font-weight: 600;
	line-height: 1;
	font-size: 1rem;
	margin-top: 0;
	margin-bottom: 0;
}

h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small,
h1 .small,
h2 .small,
h3 .small,
h4 .small,
h5 .small,
h6 .small {
	color: $color4;
	font-family: 'Source Sans Pro', sans-serif;
	font-style: normal;
	font-weight: 400;
	line-height: 1.4;
	font-size: 1.4rem;
}

h1::after,
h2::after,
#block-upsell-heading::after,
#block-related-heading::after {
	display: none;
}

a,
.alink {
	color: $color4;
	text-decoration: none;
	-webkit-transition: all .3s ease-in-out;
	transition: all .3s ease-in-out;
}

a:visited,
.alink:visited {
	color: $color4;
	text-decoration: none;
}

a:hover,
.alink:hover {
	color: $color1;
	text-decoration: none;
}

a:active,
.alink:active {
	color: $color1;
}

ul,
ol {
	margin-top: 0rem;
	margin-bottom: 2.5rem;
}

ul>li,
ol>li {
	margin-top: 0rem;
	margin-bottom: 1rem;
}

ul ul,
ol ul,
ul ol,
ol ol {
	margin-bottom: 0;
}

dl {
	margin-bottom: 2rem;
	margin-top: 0;
}

dt {
	font-weight: 700;
	margin-bottom: .5rem;
	margin-top: 0;
}

dd {
	margin-bottom: 1rem;
	margin-top: 0;
	margin-left: 0;
}

input[type="text"],
input[type="password"],
input[type="url"],
input[type="tel"],
input[type="search"],
input[type="number"],
input[type="datetime"],
input[type="email"] {
	background: $color-white;
	background-clip: padding-box;
	border: .1rem solid $color4;
	border-radius: 0;
	font-family: 'Source Sans Pro', sans-serif;
	font-size: 1rem;
	height: 2rem;
	line-height: 1.4;
	padding: 0 .9rem;
	vertical-align: baseline;
	width: 100%;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}

select {
	background: $color-white no-repeat center right /.8rem .4rem;
	background-clip: padding-box;
	border: .1rem solid $color4;
	border-radius: 0;
	font-family: 'Source Sans Pro', sans-serif;
	font-size: 1rem;
	line-height: 1;
	padding: .5rem 1rem .4rem;
	vertical-align: baseline;
	width: 100%;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;

	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	cursor: pointer;
	padding-right: 2.8rem;

	-webkit-transition: all .3s ease-in-out;

	transition: all .3s ease-in-out;
}

select:hover {
	border-bottom-color: $color4;
}

textarea {
	background: $color-white;
	background-clip: padding-box;
	border: .1rem solid $color4;
	border-radius: .1rem;
	font-family: 'Source Sans Pro', sans-serif;
	font-size: 1rem;
	height: auto;
	line-height: 1.4;
	margin: 0;
	padding: .1rem;
	vertical-align: baseline;
	width: 100%;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	resize: vertical;
}

._keyfocus *:focus,
input:not([disabled]):focus,
textarea:not([disabled]):focus,
select:not([disabled]):focus {
	-webkit-box-shadow: none;
	box-shadow: none;
}

button:active,
.cart.table-wrapper .actions-toolbar>.action:active,
.cart.table-wrapper .action-gift:active {
	-webkit-box-shadow: none;
	box-shadow: none;
}

button,
a.action.primary,
.cart.table-wrapper .actions-toolbar>.action,
.cart.table-wrapper .action-gift {
	border-radius: 0rem;
}

.action.primary {
	font-family: 'Source Sans Pro', sans-serif;
	background: $color1 none repeat scroll 0 0;
	border: 1px solid $color1;
	border-radius: 5px;
	color: $color-white;
	margin-top: 1.25rem;
	-webkit-transition: all .3s ease-in-out;
	transition: all .3s ease-in-out;
	font-size: 1rem;
}

.action.primary:hover {
	background: $color2 none repeat scroll 0 0;
	border: 1px solid $color2;
}

.action.primary:focus,
.action.primary:active {
	background: $color2;
	border: 1px solid $color2;
	color: $color4;
}

div.mage-error[generated] {
	margin-top: 0.1875rem;
}

.abs-margin-for-forms-desktop,
form .fieldset .legend,
.column:not(.sidebar-main) form div.actions-toolbar,
.column:not(.sidebar-additional) form div.actions-toolbar,
.login-container form .fieldset:after {
	margin-left: 0rem;
}

form .fieldset>.field:not(.choice)>.label,
form .fieldset>.field>.label {
	margin-left: 0rem;
	width: 100%;
	padding: 0rem;
	text-align: left;
	margin-bottom: .5rem;
}

form .fieldset>.field>.control,
form .fieldset>.field:not(.choice)>.control {
	width: 100%;
}

form .fieldset>.field.choice:before,
form .fieldset>.field.no-label:before {
	padding: 0rem;
	width: auto;
}

.page-print .sections.nav-sections {
	display: none;
}

iframe {
	max-width: 100%;
}

.fieldset>.field.required>.label::after,
.fieldset>.fields>.field.required>.label::after,
.fieldset>.field._required>.label::after,
.fieldset>.fields>.field._required>.label::after {
	vertical-align: top;
}

.filter-model .modal-header {
	padding-bottom: 2.5rem;
	padding-top: 2.5rem;
}

.modal-custom .action-close:before,
.modal-popup .action-close:before,
.modal-slide .action-close:before {
	color: $color1;
	font-weight: 600;
}


.block {
	ul {
		font-family: $font1;
		padding: 0;
		margin-left: 2rem;
		margin-bottom: 1.5rem;
		gap: 1rem;
		display: grid;
		list-style-position: outside;
		list-style-type: disc;

		li {
			margin-bottom: 0;

			&::marker {
				color: $color1;
			}
		}

		ul {
			margin-left: 2rem;
			list-style-type: square; // Aangepaste stijl voor subitems
			gap: .5rem;

			li::marker {
				color: $color2;
			}
		}
	}
}

/* ============================== BREADCRUMBS ============================= */
.page-wrapper {
	.breadcrumbs {
		display: block;
		margin: 30px auto;

		.items {
			.item {

				&.home,
				&.Home {
					a {
						text-decoration: none;
						@include link-hover;
					}

					&:not(:last-child)::after {
						-webkit-font-smoothing: antialiased;
						font-size: 1rem;
						line-height: 1.8rem;
						content: '\f054';
						font-family: $font-awesome-pro;
						margin: 0 .5rem;
						vertical-align: top;
						display: inline-block;
						font-weight: normal;
						overflow: hidden;
						speak: none;
						text-align: center;
						color: $color-black;
					}
				}

				&.category8 {
					strong {
						color: $color1;
					}
				}
			}
		}
	}
}

.abs-add-clearfix-desktop:before,
.abs-add-clearfix-desktop:after,
.abs-pager-toolbar:before,
.abs-pager-toolbar:after,
.block-cart-failed .block-content:before,
.block-cart-failed .block-content:after,
.column .block-addbysku .block-content:before,
.column .block-addbysku .block-content:after,
.cart-container:before,
.cart-container:after,
.login-container:before,
.login-container:after,
.account .column.main .block:not(.widget) .block-content:before,
.account .column.main .block:not(.widget) .block-content:after,
.block-addresses-list .items.addresses:before,
.block-addresses-list .items.addresses:after,
.block-giftregistry-shared .item-options:before,
.block-giftregistry-shared .item-options:after,
.gift-wrapping .nested:before,
.gift-wrapping .nested:after,
.table .gift-wrapping .content:before,
.table .gift-wrapping .content:after,
.block-wishlist-management:before,
.block-wishlist-management:after,
.paypal-review .block-content:before,
.paypal-review .block-content:after,
.magento-rma-guest-returns .column.main .block:not(.widget) .block-content:before,
.magento-rma-guest-returns .column.main .block:not(.widget) .block-content:after,
[class^='sales-guest-'] .column.main .block:not(.widget) .block-content:before,
[class^='sales-guest-'] .column.main .block:not(.widget) .block-content:after,
.sales-guest-view .column.main .block:not(.widget) .block-content:before,
.sales-guest-view .column.main .block:not(.widget) .block-content:after,
.header.content:before,
.header.content:after,
.page-header .header.panel:before,
.page-header .header.panel:after,
.account .toolbar:before,
.account .toolbar:after,
.toolbar-giftregistry-results:before,
.toolbar-giftregistry-results:after,
.toolbar-wishlist-results:before,
.toolbar-wishlist-results:after {
	content: none;
}

.offer-request-success {
	.page-wrapper {
		position: relative;

		&:after {
			@include bg;
			background-position-y: 100px;
			width: 100%;
			left: 0;
		}

		#maincontent {
			@include transition;
			@include box-shadow;
			width: calc(100% - 4rem);
			margin-bottom: 2rem;
			padding: 7rem;
			background-color: $color-white;
			overflow: hidden;
			margin-top: 2rem;
		}
	}
}

#maincontent{
	isolation: isolate;
}

.message.error,.message.info,.message.success,.message.notice{
	font-size: 1rem;
}

.price-including-tax, .price-excluding-tax{
	font-size: 1rem;
}

.block-cart-failed .action.continue, .cart-container .form-cart .action.continue{
	font-size: 0.875rem;
}

button, .cart.table-wrapper .actions-toolbar > .action, .action-gift{
	font-size: 0.875rem;
}


#store-selector .form-continue .actions-toolbar .action.primary,
.order-review-form .action.primary,
.bundle-actions .action.primary,
.bundle-options-container .block-bundle-summary .box-tocart .action.primary,
.product-info-main .box-tocart .action.tocart,
.product-options-bottom .box-tocart .action.tocart,
.cart-container .checkout-methods-items .action.primary,
.block-minicart .block-content > .actions > .primary .action.primary,
.methods-shipping .actions-toolbar .action.primary,
.block-authentication .action.action-register,
.block-authentication .action.action-login,
.checkout-payment-method .payment-method-content > .actions-toolbar > .primary .action.primary,
.form-address-edit .actions-toolbar .action.primary,
.multicheckout .action.primary {
	font-size: 1rem;
	line-height: 1;
}

.catalogsearch-result-index{
	// .filter-options-item{border: 1px solid #E3E3E3;margin-bottom: 10px;}
	// .filter-options .filter-options-title{
	// 	font-size: 1rem;
	// 	font-weight: 500;
	// 	line-height: 1;
	// 	margin: 0;
	// 	padding: 10px;
	// 	border-bottom: 1px solid transparent;
	// 	-webkit-transition: all 0.2s ease, font-weight .01s linear, color .01s linear;
	// 	transition: all 0.2s ease, font-weight .01s linear, color .01s linear;
	// 	cursor: pointer;
	// }
	// .filter-options-item .filter-options-title::after{
	// 	font-family: "FontAwesome";
	// 	font-size: 1.625rem;
	// 	font-weight: 500;
	// 	content: "\f105";
	// 	float: right;
	// 	line-height: 0.6;
	// 	-webkit-transition: all .1s ease;transition: all .1s ease;
	// }
	// .filter-options-item.active .filter-options-title{
	// 	background: #E3E3E3;
	// 	border-color: #E3E3E3;
	// 	color: #FFF;
	// }
	// .filter-options-item.active .filter-options-title::after{-webkit-transform: rotate(90deg);transform: rotate(90deg);}
	// .filter-options .filter-options-content{margin: 0;padding: 10px;}
	// .filter-options .filter-options-content .item{margin: 0;line-height: 3.1rem;}
	// .filter-current{border: 1px solid #E3E3E3;border-bottom: 0;}
	// .filter-current .block-subtitle{
	// 	font-size: 1rem;
	// 	font-weight: 700;
	// 	line-height: 1;
	// 	margin: 0;
	// 	padding: 10px;
	// 	display: block;
	// 	background: #E3E3E3;
	// 	border-bottom: 1px solid #E3E3E3;
	// 	color: #FFF;
	// }
	// .filter-current .item .remove span{display: none;}
	// .filter-current .item .remove::after{
	// 	content: "\f00d";
	// 	font-family: "FontAwesome";
	// 	color: #E1201B;
	// 	float: right;
	// 	-webkit-transition: color .2s ease;transition: color .2s ease;
	// }
	// .filter-current .item .remove:hover::after{color: #1C1819;}
	// .filter-current .items{padding: 10px;}
	// .filter-current .items .item{margin: 0;line-height: 3.1rem;}
	// .filter-current .items .filter-label{display: inline;}
	// .filter-current + .filter-actions{
	// 	border: 1px solid #E3E3E3;
	// 	border-top: 0;
	// 	padding: 0 10px;
	// 	margin-bottom: 10px;
	// }
	// .filter-current + .filter-actions .action{color: #1C1819;line-height: 3.1rem;}
	// .filter-current + .filter-actions .action::before{
	// 	content: "\f1f8";
	// 	font-family: "FontAwesome";
	// 	color: #E1201B;
	// 	margin-right: 5px;
	// 	-webkit-transition: color .2s ease;transition: color .2s ease;
	// }
	// .filter-current + .filter-actions .action:hover::before{color: #1C1819;}
	// .page-title{
	// 	position: relative;
	// 	margin-top: 1rem;
	// 	margin-bottom: 2rem;
	// 	::after{
	// 		content: '';
	// 		position: absolute;
	// 		left: 0;
	// 		right: 0;
	// 		bottom: -5px;
	// 		height: 3px;
	// 		background-color: #E1201B;
	// 		width: 60%;
	// 		max-width: 248px;
	// 	}
	// }
	@import '../component/category/products-list';
	// .sidebar-main{
	// 	float: none;
	// }
	.columns{
		display: grid;
		clear: both;
		.column.main{
			margin-top: 20px;
			.search.results{
				display: grid;
				grid-template-columns: 1fr 1fr;
				grid-auto-flow: dense;
				gap: 0 20px;
				margin-bottom: 20px;
				.toolbar{
					grid-column: 2;
					margin: 0;
				}
				.block{
					grid-column: 1;
					margin: 0;
				}
				.products{
					margin-top: 0;
				}
			}
		}
	}
}