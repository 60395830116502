// body {
//     &.home.cms-home {

//         .page-wrapper {
//             overflow: hidden;

//             .home-banner-wrapper {
//                 width: 100%;
//                 padding: unset;
//                 max-width: unset;
//                 margin: 0 auto;
//                 position: relative;

//                 .blocks-group {
//                     &.banner {

//                         position: absolute;
//                         inset: 0;

//                         p {
//                             margin: 0;
//                             height: 100%;
//                             width: 100%;
//                             img {
//                                 border: unset;
//                                 display: block;
//                                 height: 100%;
//                                 object-fit: cover;
//                                 width: 100%;
//                             }
//                         }


//                         &:before {
//                             content: "";
//                             width: 100%;
//                             height: 100%;
//                             background-color: $color-white;
//                             position: absolute;
//                             top: 0;
//                             opacity: 0.5;
//                         }
//                     }

//                     &.category {
//                         top: 0;
//                         width: 100%;
//                         padding-top: 140px;
//                         isolation: isolate;
                       
//                         h1 {
//                             font-size: 7rem; 
//                             text-align: center;
//                             @media(width < 780px) {
//                                 font-size: 4rem !important;
//                             }                            
//                         }
//                         @media(width < 780px){
//                             padding-top: 20px;
//                         }

//                         .full-row {
//                             margin: 0 auto;
//                             max-width: 1280px;
//                             padding: 0 20px;
//                             box-sizing: border-box;
//                             width: 100%;

//                             .inner-block {
//                                 position: relative;
//                                 text-align: center;
                                
//                                 h1 {
//                                     font-size: 9rem;
//                                     line-height: 12rem;
//                                     @media(width < 780px){
//                                         font-size: 4rem;
//                                         line-height: normal;
//                                         margin: 0;
//                                     }
//                                 }
                                
//                                 .category-wrapper{
//                                     position: relative;
//                                     .button-container {
//                                         display: flex;
//                                         justify-content: space-between;
//                                         margin-top: 10px;
//                                         position: absolute;
//                                         top: 50%;
//                                         translate: 0 -50%;
//                                         width: 100%;
//                                     }
                                    
//                                     button.terug {
//                                         background-color: #E1201B;
//                                         color: #fff;
//                                         font-weight: 900;
//                                         translate: -100%;
//                                     }
                                    
//                                     button.volgende {
//                                         background-color: #E1201B;
//                                         font-weight: 900;   
//                                         color: #fff;
//                                         translate: 100%;
//                                     }
                                    
//                                     > ul {
//                                         display: flex;
//                                         min-height: 40vh;
//                                         transition: transform 0.4s;
//                                         overflow: hidden;
                                        
//                                         @media(width < 780px){
//                                             min-height: 25vh;
//                                         }

//                                         width: 100%;
//                                         display: flex; 
//                                         //flex-wrap: wrap;
//                                         padding-left: 0;
//                                         list-style: none;
//                                         justify-content: space-between;
//                                         // display: grid;
//                                         grid-template-columns: repeat(5, minmax(0, 1fr));
//                                         grid-template-rows: 1fr max-content;
                                        
//                                         @media(width >= 780px){
//                                             margin-top: 6rem;
//                                         }
                                        
//                                         > li {
//                                             flex:1;
//                                             min-width:calc(25% - 1.4rem);

//                                             border: .7rem solid transparent;
//                                             margin: 0;
//                                             position: relative;
//                                             cursor: pointer;
//                                             @include transition(.1s);
//                                             display: grid;
//                                             grid-template-rows: subgrid;
//                                             grid-row: span 2;
//                                             align-items: center;

//                                             * {
//                                                 // font-size: 2.4rem;
//                                                 font-size: clamp(0px, 2vw, 2.4rem);
//                                                 font-weight: bold;
//                                                 color: $color-white;
//                                                 text-transform: uppercase;
//                                             }

//                                             &:hover {
//                                                 border: .7rem solid $color1;

//                                                 ul {
//                                                     li {
//                                                         color: $color-white;
//                                                     }
//                                                 }
//                                             }

//                                             >a,>ul{
//                                                 position: relative;
//                                                 &::after {
//                                                     left: 0;
//                                                     bottom: 0;
//                                                     content: '';
//                                                     z-index: -1;
//                                                     width: 100%;
//                                                     height: 100%;
//                                                     position: absolute;
//                                                     background-color: $color-black;
//                                                 }
//                                             }

//                                             > a {
//                                                 display: flex;
//                                                 width: 100%;
//                                                 height: 100%;
//                                                 text-align: center;
//                                                 justify-content: center;

//                                                 &::after{
//                                                     height: 50%;
//                                                 }

//                                                 img {
//                                                     width: 100%;
//                                                     height: auto;
//                                                     max-height: 200px;
//                                                     border: none;
//                                                     margin: 1.5rem auto 0;
//                                                     object-fit: contain;
//                                                 }
//                                             }

//                                             ul {
//                                                 padding: 0;
//                                                 bottom: 0;
//                                                 height: 100%;
//                                                 display: flex;
//                                                 li {
//                                                     width: 100%;
//                                                     border: unset;
//                                                     padding: 1rem 0;
//                                                     text-align: center;
//                                                     align-items: center;
//                                                     display: flex;
//                                                     justify-content: center;
//                                                     box-sizing: border-box;
//                                                     height: 100px;
//                                                     @media(width < 780px){
//                                                         margin: 0;
//                                                     }

//                                                     &:hover {
//                                                         border: unset;
//                                                     }

//                                                     &::after {
//                                                         display: none;
//                                                     }
//                                                 }

//                                             }

//                                         }
//                                     }
//                                 }
//                             }
//                         }
//                     }
//                 }
//             }

//             // Home CMS-blocks
//             #maincontent {
//                 position: relative;

//                 &::after {
//                     @include bg;
//                 }

//                 .columns {
//                     .column {
//                         padding-bottom: 0;

//                         .home-main.content {
//                             margin: 0;
//                             font-size: 1.6rem;
//                             padding: 12rem 0;
//                             @media (width < 780px){
//                                 padding-top: 2rem;
//                             }

//                             .home-main {
//                                 &.block1 {
//                                     @include transition;
//                                     @include box-shadow;
//                                     background-color: $color-white;
//                                     overflow: hidden;
//                                     @media (width < 780px){
//                                         padding-top: 20px;
//                                     }

//                                     .inner-block {
//                                         &.block-top {
//                                             padding: 7rem;
//                                             @media(width < 780px){
//                                                 padding: 0 2rem;
//                                             }

//                                             .block {
//                                                 margin: 0 0 20px;

//                                                 &.title {
//                                                     font-size: 40px;
//                                                     font-weight: 700;
//                                                     text-transform: uppercase;
//                                                     width: 100%;
//                                                 }

//                                                 &.content {
//                                                     display: flex;
//                                                     flex-direction: row;
//                                                     gap: 100px;

//                                                     .text {
//                                                         display: inline-grid;
//                                                         align-content: space-between;
//                                                         width: 100%;
//                                                     }

//                                                     @media(width < 780px){
//                                                         columns: unset;
//                                                         display: block;
//                                                     }

//                                                     ul {
//                                                         display: block;
//                                                     }                                                    
//                                                     a {
//                                                         margin-top: 4rem;
//                                                         @include button;
//                                                     }
//                                                 }
//                                             }
//                                         }

//                                         &.block-bottom {
//                                             line-height: 0;

//                                             .block {
//                                                 &.image {
//                                                     background-color: $color-white;
//                                                     width: 100%;

//                                                     p {
//                                                         display: grid;
//                                                         grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
//                                                         height: 25rem;
//                                                         width: 100%;

//                                                         img {
//                                                             height: 100%;
//                                                             object-fit: cover;
//                                                             object-position: center center;
//                                                             width: 100%;
//                                                         }
//                                                     }
//                                                 }
//                                             }
//                                         }
//                                     }

//                                 }

//                                 &.block2 {
//                                     margin-top: 12rem;
//                                     @media(width < 780px){
//                                         margin-top: 2rem;
//                                     }

//                                     .blocks-container {
//                                         gap: 2rem;
//                                         width: 100%;
//                                         display: flex;
//                                         justify-content: space-between;
//                                         @media(width < 780px){
//                                             flex-wrap: wrap;
//                                         }

//                                         .blocks-group {
//                                             display: flex;
//                                             flex-direction: column;
//                                             width: 50%;
//                                             padding: 7rem;
//                                             position: relative;
//                                             color: $color-white;
//                                             background-color: $color-black;
//                                             @include transition;
//                                             @include box-shadow;

//                                             @media(max-width: $bp-1024px){
//                                                 padding: 2rem;
//                                             }

//                                             @media(width < 780px) {
//                                                 width: 100%;
//                                             }

//                                             p {
//                                                 margin: 0;
//                                             }

//                                             p:not(:last-child) {
//                                                 margin-bottom: 20px;
//                                             }
//                                             p:last-child {
//                                                 margin-top: auto;
//                                                 a {
//                                                     @include button;
//                                                 }
//                                             }

//                                             &.cms-block2 {
//                                                 background-color: $color-black !important;
//                                                 border: .2rem solid $color-black !important;
//                                                 @include block-hover;
//                                                 @media(width < 780px){
//                                                     width: 100%;
//                                                 }

//                                                 h2{
//                                                     font-size: 40px;
//                                                     font-weight: 700;
//                                                     text-transform: uppercase;
//                                                     width: 100%;
//                                                     word-break: break-word;
//                                                 }

//                                                 p:last-child {
//                                                     a {
//                                                         &:hover {
//                                                             color: $color-white !important;
//                                                             background-color: $color-black !important;
//                                                             border: .2rem solid $color-black !important
//                                                         }
//                                                     }
//                                                 }
//                                             }

//                                             &.cms-block3 {
//                                                 @include block-hover;
//                                                 display: flex;
//                                                 flex-direction: column;
//                                                 background-color: $color1;

//                                                 h2{
//                                                     font-size: 40px;
//                                                     font-weight: 700;
//                                                     text-transform: uppercase;
//                                                     width: 100%;
//                                                     word-break: break-word;
//                                                 }

//                                                 p:last-child {
//                                                     margin-top: auto;
//                                                     a {
//                                                         background-color: $color-black;
//                                                         border: .2rem solid $color-black;

//                                                         &:hover {
//                                                             color: $color-white !important;
//                                                             background-color: $color1 !important;
//                                                             border: .2rem solid $color1;
//                                                         }
//                                                     }
//                                                 }
//                                             }
//                                         }
//                                     }
//                                 }
//                             }

//                         }
//                     }
//                 }
//             }


//             // Uitgelichte producten
//             .home-product-wrapper {
//                 .home-product-container {
//                     width: 100%;
//                     margin: 0 auto;
//                     max-width: 1280px;
//                     padding: 0 20px;
//                     box-sizing: border-box;

//                     h2 {
//                         width: 100%;
//                         text-align: center;
//                         font-size: 40px;
//                         font-weight: 700;
//                         text-transform: uppercase;
//                     }

//                     .block {
//                         &.block-products-list {
//                             margin-bottom: 3rem;

//                             .block-content {
//                                 .products-grid {
//                                     .product-items {
//                                         gap: 2rem;
//                                         display: grid;
//                                         grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));

//                                         .product-item {
//                                             margin: 0;
//                                             width: 100%;
//                                             @include transition;
//                                             @include box-shadow;

//                                             .product-item-info {
//                                                 .product-item-photo {
//                                                     margin: 0;

//                                                     .product-image-container {
//                                                         width: 100% !important;
//                                                     }
//                                                 }

//                                                 .product-item-details {
//                                                     background-color: $color1;
//                                                     padding: 3rem;

//                                                     .product-item-name {
//                                                         a {
//                                                             font-size: 2.4rem;
//                                                             line-height: 2rem;
//                                                             color: $color-white;
//                                                             text-transform: uppercase;
//                                                             font-weight: $font-weight-bold;
//                                                             @include transition;
//                                                             display: block !important;

//                                                             &:hover {
//                                                                 text-decoration: none;
//                                                                 color: $color-black;
//                                                             }
//                                                         }
//                                                     }

//                                                     .product-attributes {
//                                                         margin: 2rem 0 0 0;
//                                                         color: $color-white;
//                                                         font-size: 16px;

//                                                         ul.attribute-list {
//                                                             margin: 0;

//                                                             li {
//                                                                 position: relative;
//                                                                 padding-left: 2rem;

//                                                                 &:after {
//                                                                     top: 0;
//                                                                     left: 0;
//                                                                     content: '\f00c';
//                                                                     font-size: 1.6rem;
//                                                                     position: absolute;
//                                                                     font-family: $font-awesome-pro;
//                                                                     transform: translateY(5%);
//                                                                 }
//                                                             }
//                                                         }
//                                                     }

//                                                     a.product-item-link {
//                                                         display: none;
//                                                     }
//                                                 }
//                                             }
//                                         }
//                                     }

//                                 }
//                             }

//                         }
//                     }

//                     p {
//                         width: 100%;
//                         text-align: center;

//                         a {
//                             @include button;
//                             background-color: $color-black;
//                             border: 2px solid $color-black;
//                         }
//                     }
//                 }
//             }


//             //  Shape slider
//             .home-shape-wrapper {
//                 display: flex;
//                 justify-content: center;
//                 background-color: $color2 !important;
//                 padding: 7rem 0;

//                 .shape-slider {
//                     box-sizing: border-box;
//                     margin: 0 auto;
//                     max-width: 1280px;
//                     padding: 0 20px;
//                     min-width: 0;

//                     .title {
//                         text-align: center;
//                     }

//                     h2 {
//                         &::after {
//                             margin: 0 auto;
//                         }
//                     }
//                 }

//                 .slider {
//                     overflow: hidden;

//                     >ul {
//                         &:not(.slick-slider) {
//                             display: none;
//                         }
//                     }

//                     ul {
//                         position: relative;
//                         padding: 0;
//                         margin: 0;

//                         img {
//                             &:hover {
//                                 -webkit-filter: invert(40%) grayscale(100%) brightness(40%) sepia(100%) hue-rotate(-50deg) saturate(800%) contrast(2) drop-shadow(0px 10px 10px rgba(0, 0, 0, 0.16));
//                                 filter: grayscale(100%) brightness(40%) sepia(100%) hue-rotate(-50deg) saturate(800%) contrast(0.8) drop-shadow(0px 10px 10px rgba(0, 0, 0, 0.16));
//                             }
//                         }
//                     }

//                     .slick-arrow {
//                         font-size: 0;
//                         position: absolute;
//                         left: 0;
//                         top: 50%;
//                         -webkit-transform: translateY(-50%);
//                         transform: translateY(-50%);
//                         width: 31px;
//                         height: 45px;
//                         // background-image: url('../images/previous.png');
//                         // background-position: center;
//                         // background-repeat: no-repeat;
//                         background-color: transparent;
//                         border: 0;
//                         padding: 0;
//                         z-index: 1;

//                         &:after {
//                             font-size: 3rem;
//                             color: $color-black;
//                             font-family: $font-awesome-pro;
//                             font-weight: $font-weight-semi;
//                         }
//                     }

//                     .slick-prev {
//                         left: 0;
//                         right: auto;
//                     }

//                     .slick-next {
//                         // background-image: url('../images/next.png');
//                         left: auto;
//                         right: 0;

//                         &:after {
//                             content: '\f054';
//                         }
//                     }

//                     .title {
//                         margin: 0 0 20px;

//                         h2 {
//                             margin: 0;
//                         }
//                     }
//                 }

//                 .slick-track {
//                     font-size: 0;

//                     .slick-slide {
//                         display: inline-block;
//                         vertical-align: middle;
//                         text-align: center;

//                         img {
//                             padding: 20px;
//                             -webkit-box-sizing: border-box;
//                             box-sizing: border-box;
//                             -webkit-transition: all .1s ease-in-out;
//                             transition: all .1s ease-in-out;
//                         }
//                     }
//                 }
//             }
//         }

//     }
// }

// //Slider responsiveness
// @media only screen and (max-width: 580px) {
//     body.home.cms-home .page-wrapper .home-banner-wrapper .blocks-group.category .full-row .inner-block .category-wrapper > ul > li{
//         min-width: calc(50% - 1.4rem);
//         height: 200px;
//     }
//     body.home.cms-home .page-wrapper .home-banner-wrapper .blocks-group.category .full-row{
//         width: 85%;
//     }
//     body.home.cms-home .page-wrapper .home-banner-wrapper .blocks-group.category .full-row .inner-block .category-wrapper > ul > li *{
//         font-size: clamp(0px, 4vw, 2.2rem);
//     }
//     body.home.cms-home .page-wrapper .home-banner-wrapper .blocks-group.category .full-row .inner-block .category-wrapper > ul > li ul li{
//         margin-top: -1px;
//         padding: 0;
//     }
//     body.home.cms-home .page-wrapper .home-banner-wrapper .blocks-group.category .full-row .inner-block .category-wrapper > ul > li > a img{
//         margin: 0;
//         margin-bottom: -20%;
//         width: 85%;
//     }
//   }

body.cms-index-index{
    overflow-x: hidden;

    .column.main{
        padding: 0;
    }
    @import "../component/widgets/banner-row";
    @import "../component/widgets/two-column-text-row";
    @import "../component/widgets/text-slider-row";
    @import "../component/widgets/highlighted-products-row";
    @import "../component/widgets/shapes-row";
}