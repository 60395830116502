body.cms-no-route {
    .page-wrapper {
        position: relative;

        &:after {
            @include bg;
            background-position-y: 100px;
            width: 100%;
            left: 0;
        }

        #maincontent {
            @include transition;
            @include box-shadow;
            width: unset;
            margin: 2rem auto;
            padding: 7rem;
            background-color: $color-white;
            overflow: hidden;
            width: 100%;

            .page-title-wrapper {
                text-align: center;

                .page-title {
                    font-weight: bold;
                    font-size: 15rem;
                    color: $color1;
                }
            }

            .column.main {
                min-height: auto;
                text-align: center;

                p {
                    font-size: 2.5rem;

                    a {
                        @include link-hover;
                    }
                }
            }
        }
    }
}