// COLOR VARIABLES
$color1: #E1201B;
$color2: #D6D5D1;
$color3: #F7F7F6;
$color4: #1C1819;
$color5: #E3E3E3;
$color6: #F2F2F1;

$color-white: #FFFFFF;
$color-black: #000000;
$color-green: #5aa466;

$color-buro210: #ED0579;


// FONT VARIABLES
$font1: 'Source Sans Pro', sans-serif;
$font-weight-light: 300;
$font-weight-semi: 500;
$font-weight-bold: 700;
$font-weight-bolder: 900;
$font-awesome-pro: 'Font Awesome 6 Pro';
$font-awesome: 'FontAwesome';

$font-size1: 1rem;

// MEDIA QUERIES
// PX to EM Convert: https://www.w3schools.com/tags/ref_pxtoemconversion.asp
// Magento Breakpoints: https://devdocs.magento.com/guides/v2.4/frontend-dev-guide/responsive-web-design/rwd_css.html
$bp-1440px: 90em;
$bp-1024px: 64em;
$bp-768px: 48em;
$bp-640px: 40em;
$bp-480px: 30em;
$bp-320px: 20em;