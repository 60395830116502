body {

    .page-wrapper {
        .page-footer {
            background-color: $color3;
            isolation: isolate;

            .footer-usps {
                background-color: $color1;

                ul {
                    max-width: 1280px;
                    box-sizing: border-box;
                    margin: 0 auto;
                    padding: 1rem;
                    display: flex;
                    justify-content: space-between;
                    color: $color-white;
                    text-transform: uppercase;
                    flex-wrap: wrap;

                    li {
                        display: block;
                        margin: 0;
                        font-size: 1.25rem;

                        &::before {
                            content: '\f00c';
                            font-family: $font-awesome-pro;
                            display: inline-block;
                            margin-right: .8rem;
                        }
                    }
                }
            }

            .footer.content {
                display: grid;
                grid-template-columns: repeat(4, 1fr);
                border-top: 0;
                margin: 2.5rem auto;

                .footer-block {
                    font-size: 1rem;
                    display: inline-block;
                    vertical-align: top;
                    -webkit-box-sizing: border-box;
                    box-sizing: border-box;
                    padding-right: .2rem;
                    word-break: break-word;

                    h5 {
                        font-size: 1rem;
                        text-transform: uppercase;
                        font-weight: bold;
                        margin: 0;
                    }

                    p {
                        margin: 0;

                        a {
                            @include link-hover;
                        }
                    }

                }
            }

            .footer-copyright {
                max-width: 1280px;
                position: relative;
                margin: 0 auto;
                padding: 10px 20px;
                box-sizing: border-box;
                display: flex;
                justify-content: center;
                align-items: center;

                ul.socials {
                    order: 1;
                    margin: 0 2rem 0 0;

                    li a {
                        color: $color-black;
                        @include transition;
                        &::before{
                            font-size: 25px;
                        }

                        &:hover {
                            color: $color1;
                        }
                    }

                    li+li{
                        margin-left: 10px;
                    }
                }

                p:nth-child(1) {
                    order: 2;
                    margin-right: auto;
                }

                p:nth-child(2),
                p:last-child {
                    order: 3;
                    margin-left: 0;
                }

                p {
                    display: inline-block;
                    margin-bottom: 0;

                    a[href*="https://www.buro210.nl/"] {

                        @include transition;

                        &:hover {
                            background: linear-gradient(45deg, $color-buro210, $color-black);
                            -webkit-background-clip: text;
                            color: transparent;
                            font-weight: $font-weight-semi;
                        }
                    }
                }

                &:before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 20px;
                    right: 20px;
                    background-color: $color-black;
                    height: 1px;
                }
            }
        }
    }

    .totop {
        a {
            position: fixed !important;
            right: 1.875rem;
            bottom: 3.4375rem;
            width: 2.5rem;
            height: 2.5rem;
            z-index: 3;
            padding: 0 !important;
            @include transition();
            @include box-shadow;
            @include button;
            background-color: #5AA466;
            border-color: #5AA466;
            border-radius: 5px;

            i {
                font-family: $font-awesome-pro;
                font-size: $font-size1;
                font-weight: $font-weight-semi;
                position: absolute;
                color: $color-white;
                left: 50%;
                top: 50%;
                transform: translateX(-50%) translateY(-50%);
                @include transition();
            }

            &:hover {
                background-color: $color-black !important;
                border: 2px solid $color-black !important;

                &:before {
                    margin-left: 550% !important;
                }
            }
        }
    }

    .footer-contact-wrapper {
        @include bg3;
        background-color: $color-white;
        padding: 7rem 0;
        .full-row {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 4.375rem;
            margin: 0 auto;
            width: 100%;
            max-width: 1280px;
            padding: 0 20px;
            box-sizing: border-box;
            position: relative;
            z-index: 1;

            @media(width < 780px) {
                grid-template-columns: 1fr;
                gap: 0;
            }
            
            .contact-block-wrapper{
                background-color: $color1;
                height: max-content;
                >p{
                    margin: 0;
                    padding: 0;
                }
                img{
                    display: block;
                    width: 100%;
                    @media(width < 767px){
                        display: none;
                    }
                }
                .contact-message{
                    background-color: $color1;
                    margin: 0;
                    color: $color-white;
                    padding: 5px 25px;
                }
            }
            .form {
                &.contact {
                    z-index: 1;
                    padding: 7rem;
                    color: $color-white;
                    position: relative;
                    background-color: $color-black;
                    box-sizing: border-box;
                    @include transition;
                    @include box-shadow;

                    @media(width < 780px) {
                        padding: 2rem;
                        // width: 100%;
                    }

                    .fieldset {
                        margin-bottom: 20px;

                        .legend {
                            font-size: 2.1875rem;
                            font-weight: bold;
                        }

                        .field-group {
                            display: flex;
                            width: 100%;
                            gap: 2rem;
                            margin-bottom: 2rem;

                            &.row4 {
                                margin-bottom: 0;
                            }

                            .field {
                                width: 50%;

                                input {
                                    border: 0;
                                    padding: 0;
                                    color: $color-white;
                                    background: transparent;
                                    border-bottom: .1rem solid $color-white;
                                }

                                input[type="text"]::-webkit-input-placeholder,
                                & input[type="email"]::-webkit-input-placeholder {
                                    color: $color-white;
                                    font-size: 16px;
                                }

                                &.comment,
                                &.branch {
                                    width: 100%;

                                    label {
                                        font-size: 16px;
                                        font-weight: normal;
                                    }

                                    .control {
                                        margin-top: 5px;

                                        textarea {
                                            color: $color-white;
                                            border: 1px solid $color-white;
                                            background: transparent;
                                        }
                                    }
                                }


                            }
                        }

                        .field-recaptcha {
                            z-index: 1;
                            position: relative;
                        }
                    }

                    .actions-toolbar {
                        width: 100%;

                        .primary {
                            display: flex;
                            align-items: center;

                            .action {
                                &.submit {
                                    margin: 0;
                                    @include button;
                                }
                            }

                            .custom-text {
                                margin-left: 30px;
                            }
                        }
                    }
                }
            }
        }
    }
}