.checkout-cart-index {
    .page-wrapper {
        position: relative;

        &:after {
            @include bg;
            background-position-y: 100px;
            width: 100%;
            left: 0;
        }

        #maincontent {
            @include transition;
            @include box-shadow;
            width: calc(100% - 4rem);
            margin-top: 2rem;
            margin-bottom: 2rem;
            padding: 4.375rem;
            background-color: $color-white;
            overflow: hidden;
            @media(width < 780px){
                margin: 0 auto 2rem;
                box-sizing: border-box;
                padding: 3rem;
            }

            .cart.table-wrapper {

                .items thead+.item,
                .items>.item {
                    border-color: $color6;
                }

                th {
                    font-weight: 300;
                }

                .price-including-tax,
                .price-excluding-tax {
                    font-weight: 300;
                    font-size: 1rem;
                }

                .item-info {
                    input {
                        border-color: $color6;
                        font-weight: 300;
                    }
                }
            }

            .cart.main.actions {
                .action {
                    @include button;
                }
            }

            .offer-summary {
                .title {
                    text-transform: uppercase;
                }

                .label {
                    font-size: 16px;
                    font-weight: normal;
                }

                select {
                    border: 0;
                    border-bottom: .1rem solid;
                    border-color: $color6;
                    font-weight: 300;
                }

                input:hover,
                input:focus,
                input:active {
                    border-color: $color1;
                }

                .primary {
                    @include button;
                    width: 100%;
                    margin: 0;
                }
            }
        }

        .footer-contact-wrapper .full-row {
            padding: 0;
        }
    }
}